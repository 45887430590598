<button
  (click)="toggle()"
  *ngIf="this.authorizationService.isStandardAuth() && !displaySaveDialog"
>
  <i class="fa-solid fa-floppy-disk"></i>
  <label>Save search</label>
</button>

<div *ngIf="displaySaveDialog">
  <form [formGroup]="savedSearchForm" class="w-full">
    <i class="fa-regular fa-keyboard"></i>
    <input
      id="name"
      formControlName="name"
      type="text"
      placeholder="Save search as..."
      autocomplete="off"
    />
    <button [disabled]="saveActionState" (click)="save()">
      <i class="fa-regular fa-floppy-disk"></i>
      <ngx-spinner
        name="save"
        bdColor="transparent"
        size="small"
        color="#000"
        type="ball-beat"
        [fullScreen]="false"
      ></ngx-spinner>
    </button>
    <button
      [disabled]="saveActionState"
      (click)="displaySaveDialog = !displaySaveDialog"
    >
      <i class="fa-solid fa-xmark"></i>
    </button>
  </form>
</div>
