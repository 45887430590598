import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DealInfoFilterType } from '../../../data/type/filter/deal/deal-info-filter.type';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'mul-deal-info-filter',
  templateUrl: './deal-info-filter.component.html'
})
export class DealInfoFilterComponent implements OnInit {
  @Output() dataMutationEventEmitter: EventEmitter<
    DealInfoFilterType
  > = new EventEmitter();

  @Input() filterSelection: DealInfoFilterType = {} as DealInfoFilterType;

  filterForm: FormGroup = this.formBuilder.group({
    dateMin: '',
    dateMax: '',
    evMin: '',
    evMax: '',
    dealSizeMin: '',
    dealSizeMax: '',
    revenueMin: '',
    revenueMax: '',
    ebitdaMin: '',
    ebitdaMax: ''
  });

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.filterForm.get('dateMin')?.patchValue(this.filterSelection.date?.min);

    this.filterForm.get('dateMax')?.patchValue(this.filterSelection.date?.max);

    this.filterForm
      .get('enterpriseValue')
      ?.patchValue(this.filterSelection.enterpriseValue?.min);

    this.filterForm
      .get('enterpriseValue')
      ?.patchValue(this.filterSelection.enterpriseValue?.max);

    this.filterForm
      .get('dealSize')
      ?.patchValue(this.filterSelection.dealSize?.min);

    this.filterForm
      .get('dealSize')
      ?.patchValue(this.filterSelection.dealSize?.max);

    this.filterForm
      .get('revenueMin')
      ?.patchValue(this.filterSelection.evRevenue?.min);

    this.filterForm
      .get('revenueMax')
      ?.patchValue(this.filterSelection.evRevenue?.max);

    this.filterForm
      .get('ebitdaMin')
      ?.patchValue(this.filterSelection.evEbitda?.min);

    this.filterForm
      .get('ebitdaMax')
      ?.patchValue(this.filterSelection.evEbitda?.max);
  }

  onFormItemChange() {
    this.filterSelection.date = {
      min: this.filterForm.get('dateMin')?.value,
      max: this.filterForm.get('dateMax')?.value
    };

    this.filterSelection.enterpriseValue = {
      min: this.filterForm.get('evMin')?.value,
      max: this.filterForm.get('evMax')?.value
    };

    this.filterSelection.dealSize = {
      min: this.filterForm.get('dealSizeMin')?.value,
      max: this.filterForm.get('dealSizeMax')?.value
    };

    this.filterSelection.evRevenue = {
      ...this.filterSelection.evRevenue,
      min: this.filterForm.get('evRevenueMin')?.value,
      max: this.filterForm.get('evRevenueMax')?.value
    };

    this.filterSelection.evEbitda = {
      ...this.filterSelection.evEbitda,
      min: this.filterForm.get('evEbitdaMin')?.value,
      max: this.filterForm.get('evEbitdaMax')?.value
    };

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setRevenueExcludeNonReportedItems(event: boolean) {
    this.filterSelection.evRevenue.excludeNonReportedItems = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setEbitdaExcludeNonReportedItems(event: boolean) {
    this.filterSelection.evEbitda.excludeNonReportedItems = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setStrategicMA(event: boolean) {
    this.filterSelection.strategicMA = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setIsPrimaryBuyOut(event: boolean) {
    this.filterSelection.isPrimaryBuyOut = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setIsPortfolioAddOn(event: boolean) {
    this.filterSelection.isPortfolioAddOn = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  onFinancialBuyOutChange(event: boolean) {
    this.filterSelection.isPrimaryBuyOut = event;
    this.filterSelection.isPortfolioAddOn = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }
}
