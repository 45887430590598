import { inject, Injectable } from '@angular/core';
import { StoredGridFilterService } from '../stored-grid-filter.service';
import { StoredGridFilterFactory } from '../../data/type/filter/stored-grid.type';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { GridDataResponse } from '../../data/type/grid/grid-data-response.type';
import { PublicDataService } from '../data-sheet/public-data/public-data.service';

@Injectable({ providedIn: 'root' })
export class StoredGridFilterInterceptorService {
  constructor(
    private readonly storedGridFilterService: StoredGridFilterService,
    private readonly dataSheetService: PublicDataService
  ) {}

  resolve(storedGridFilterId: string) {
    let storedGrid = StoredGridFilterFactory.generate();

    this.storedGridFilterService
      .findById$(storedGridFilterId)
      .subscribe(val => (storedGrid = val));

    return this.dataSheetService.getAll$(storedGrid.view);
  }
}

export const storedGridFilterInterceptor: ResolveFn<Observable<
  GridDataResponse
>> = (route: ActivatedRouteSnapshot) =>
  inject(StoredGridFilterInterceptorService).resolve(
    route.params['storedGridFilterId']
  );
