import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private dbName = 'multiples-db';
  private storeName = 'data';

  constructor(private db: IDBDatabase) {
    const request = window.indexedDB.open(this.dbName, 1);
    const objectStore = this.db.createObjectStore(this.storeName);
  }
}
