<div class="flex h-screen">
  <div #filter class="flex sticky top-0 z-10">
    <mul-navigation-user
      class="nav flex fixed z-[100] h-full"
      *ngIf="authorizationService.isStandardAuth()"
    ></mul-navigation-user>

    <div
      class="w-[300px] p-0 bg-primary-50 dark:bg-inverse-50 border-r
      border-primary-200 dark:border-inverse-200 relative {{
        authorizationService.isStandardAuth() ? 'ml-[50px]' : ''
      }}"
    >
      <mul-public-data-filter
        *ngIf="dataType === DataType.PUBLIC"
      ></mul-public-data-filter>
      <mul-deal-filter *ngIf="dataType === DataType.DEALS"></mul-deal-filter>
    </div>
  </div>

  <main class="flex flex-col w-full scrollable ">
    <div class="flex flex-col">
      <mul-data-grid class="grid"></mul-data-grid>
    </div>
  </main>
</div>
