import { Component, effect } from '@angular/core';
import { toast } from '../../service/snack-bar.service';
import { SnackMessageType } from '../../data/type/snackMessage.type';
import { SnackMessageStatusEnum } from '../../data/enum/snackMessageStatus.enum';

@Component({
  selector: 'mul-snackbar',
  templateUrl: './snackbar.component.html'
})
export class SnackbarComponent {
  protected readonly SnackMessageStatusEnum = SnackMessageStatusEnum;
  visible = false;
  toast: SnackMessageType = {} as SnackMessageType;

  constructor() {
    effect(() => {
      this.toast = toast();
      this.visible = true;

      setTimeout(() => {
        this.visible = false;
      }, this.toast.durationInSeconds! * 1000);
    });
  }

  close() {
    this.visible = false;
  }
}
