<div
  #uiContainer
  [formGroup]="form"
  class="single-select"
  (mouseleave)="toggle()"
>
  <div class="flex items-center cursor-pointer" (click)="toggle()">
    <span>{{ selectedItem }}</span>
    <i class="fa-solid fa-angle-down ml-1"></i>
  </div>
  <div class="options" *ngIf="selectStatus">
    <span *ngFor="let item of options; let i = index" (click)="select(item)">
      {{ item.label }}
    </span>
  </div>
</div>
