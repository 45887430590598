import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeographyService } from '../geography.service';
import { ResolveFn } from '@angular/router';
import { UIOptionType } from '../../data/type/filter/UIOption.type';

@Injectable({ providedIn: 'root' })
export class GeographyFilterResolverService {
  constructor(private geographyService: GeographyService) {}

  resolve() {
    return this.geographyService.getAll$();
  }
}

export const geographyFilterResolver: ResolveFn<Observable<
  UIOptionType[]
>> = () => inject(GeographyFilterResolverService).resolve();
