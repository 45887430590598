import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UIOptionType } from '../../../data/type/filter/UIOption.type';
import { TargetFilterType } from '../../../data/type/filter/deal/target-filter.type';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'mul-target-filter',
  templateUrl: './target-filter.component.html'
})
export class TargetFilterComponent implements OnInit {
  @Output() dataMutationEventEmitter: EventEmitter<
    TargetFilterType
  > = new EventEmitter();

  @Input() filterSelection: TargetFilterType = {} as TargetFilterType;

  @Input() geographyDataSet: UIOptionType[] = [];
  @Input() verticalDataSet: UIOptionType[] = [];
  @Input() targetOrganizationDataSet: UIOptionType[] = [];

  geographySelection: UIOptionType[] = [];
  verticalSelection: UIOptionType[] = [];
  targetOrganizationSelection: UIOptionType[] = [];

  filterForm: FormGroup = this.formBuilder.group({
    keywords: '',
    revenueMin: '',
    revenueMax: '',
    ebitdaMin: '',
    ebitdaMax: ''
  });

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.geographySelection = this.geographyDataSet.filter(it =>
      this.filterSelection.geographySelection?.includes(it.ref)
    );

    this.verticalSelection = this.verticalDataSet.filter(it =>
      this.filterSelection.verticalSelection?.includes(it.ref)
    );

    this.targetOrganizationSelection = this.targetOrganizationDataSet.filter(
      it => this.filterSelection.organizationSelection?.includes(it.ref)
    );

    this.filterForm
      .get('keywords')
      ?.patchValue(this.filterSelection.keywords?.join(', '));

    this.filterForm
      .get('revenueMin')
      ?.patchValue(this.filterSelection.revenue?.min);

    this.filterForm
      .get('revenueMax')
      ?.patchValue(this.filterSelection.revenue?.max);

    this.filterForm
      .get('ebitdaMin')
      ?.patchValue(this.filterSelection.ebitda?.min);

    this.filterForm
      .get('ebitdaMax')
      ?.patchValue(this.filterSelection.ebitda?.max);
  }

  setVerticals($event: string[]) {
    this.filterSelection.verticalSelection = $event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setOrganizations($event: string[]) {
    this.filterSelection.organizationSelection = $event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setGeography($event: string[]) {
    this.filterSelection.geographySelection = $event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  onFormItemChange() {
    this.filterSelection.revenue = {
      ...this.filterSelection.revenue,
      min: this.filterForm.get('revenueMin')?.value,
      max: this.filterForm.get('revenueMax')?.value
    };

    this.filterSelection.ebitda = {
      ...this.filterSelection.ebitda,
      min: this.filterForm.get('ebitdaMin')?.value,
      max: this.filterForm.get('ebitdaMax')?.value
    };

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setRevenueExcludeNonReportedItems(event: boolean) {
    this.filterSelection.revenue.excludeNonReportedItems = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setEbitdaExcludeNonReportedItems(event: boolean) {
    this.filterSelection.ebitda.excludeNonReportedItems = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setIncludeOrganizations(event: boolean) {
    this.filterSelection.includeOrganizations = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setConditionalIncludeOrganizations(event: boolean) {
    this.filterSelection.conditionalIncludeOrganizations = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  onFormKeywordsItemChange() {
    const keywords: string = this.filterForm.get('keywords')?.value;

    this.filterSelection.keywords = keywords.length
      ? keywords.split(',').map(it => it.trim())
      : [];

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }
}
