import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AppRoutes } from '../../data/enum/routes.enum';
import { AuthUserStore } from '../../../data-sheet/service/store/auth-user.store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(
    private readonly authUserStore: AuthUserStore,
    private readonly router: Router
  ) {}

  canActivate() {
    return this.authUserStore.user$.pipe(
      take(1),
      map(user => {
        const isAuth = !!user;

        if (isAuth) {
          return true;
        }

        return this.router.createUrlTree([AppRoutes.AUTHORIZATION_LOGIN]);
      })
    );
  }
}

export const authGuardService: CanActivateFn = (): Observable<
  boolean | UrlTree
> => inject(AuthGuardService).canActivate();
