import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationItemType } from '../../data/type/navigation-item.type';

@Injectable({
  providedIn: 'root'
})
export class NavigationStore {
  private _navigation: BehaviorSubject<
    NavigationItemType[]
  > = new BehaviorSubject<NavigationItemType[]>([]);

  private _navigationState: BehaviorSubject<
    NavigationItemType
  > = new BehaviorSubject<NavigationItemType>({} as NavigationItemType);

  readonly navigation$ = this._navigation.asObservable();

  readonly navigationState$ = this._navigationState.asObservable();

  get navigation() {
    return this._navigation?.getValue();
  }

  set navigation(value: NavigationItemType[]) {
    this._navigation.next(value);
  }

  get navigationState() {
    return this._navigationState?.getValue();
  }

  set navigationState(value: NavigationItemType) {
    this._navigationState.next(value);
  }

  get defaultNavigation() {
    return this.navigation.find(it => it.default) || null;
  }
}
