<div class="flex flex-row justify-center ml-3 switch">
  <label class="inline-flex items-center  justify-center my-2 cursor-pointer">
    <input
      type="checkbox"
      value="true"
      class="peer sr-only"
      (change)="setAction()"
      [checked]="record[field.ref] === true"
    />
    <div
      class="
      relative w-5 h-3 bg-primary-400 dark:bg-inverse-400 rounded-full
      peer-focus:outline-none peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white
      peer-checked:bg-green-400
      dark:peer-checked:bg-green-600
      after:content-['']
      after:absolute
      after:top-[2px]
      after:start-[2px]
      after:bg-white
      after:border-gray-300
      after:border
      after:rounded-full
      after:h-[0.5rem]
      after:w-[0.5rem]
      after:transition-all"
    ></div>
  </label>
</div>
