import { Injectable } from '@angular/core';
import { DataSheetStore } from './store/data-sheet.store';
import { DataPaginationStore } from './store/data-pagination.store';
import { GridData } from '../data/type/organization/grid-data.type';

@Injectable({
  providedIn: 'root'
})
export class DataPaginationService {
  constructor(
    public readonly dataPaginationStore: DataPaginationStore,
    private readonly dataSheetStorageService: DataSheetStore
  ) {
    this.dataSheetStorageService.gridDisplayData$.subscribe(val => {
      console.debug(
        'PAGING: gridDisplayData$',
        val.length,
        this.dataPaginationStore.page
      );

      this._paginate(val, this.dataPaginationStore.page);
    });

    this.dataPaginationStore.page$.subscribe(val => {
      const data = this.dataSheetStorageService.gridDisplayData;

      if (data.length > 0) {
        console.debug('PAGING: page$', data.length, val);

        this._paginate(data, val);
      }
    });
  }

  calculateTotalPages(length: number, pageSize: number) {
    return Math.ceil(length / pageSize);
  }

  pageRange(
    currentPage: number,
    paginationLength: number,
    totalPages: number
  ): number[] {
    const mod = currentPage % paginationLength;

    let start = currentPage - mod;
    start = start ? start : 1;

    let end = currentPage + paginationLength - mod;
    end = end > totalPages ? totalPages : end;

    if (end - start < paginationLength - 1) {
      start = start - paginationLength + end - start;
      start = start < 1 ? 1 : start;
    }

    return this._rangeArray(start, end);
  }

  private _rangeArray(start: number, stop: number, step = 1): number[] {
    return Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );
  }

  private _paginate(data: GridData[], page = 1) {
    this.dataPaginationStore.offSet =
      (page - 1) * this.dataPaginationStore.pageSize;

    this.dataPaginationStore.limit =
      this.dataPaginationStore.offSet + this.dataPaginationStore.pageSize - 1;

    this.dataSheetStorageService.gridDisplayPageData = data.slice(
      this.dataPaginationStore.offSet,
      this.dataPaginationStore.limit
    );
  }
}
