<main class="h-screen flex flex-col items-center justify-center tracking-tight">
  <div class="auth mb-5" [style]="{ width: '22rem' }">
    <h1 class="flex flex-col items-center mb-5">
      <a href="{{ env.site_url }}"></a>
    </h1>

    <form
      class="flex flex-col items-center justify-center flex-1"
      (ngSubmit)="onSubmitForm()"
      [formGroup]="formGroup"
    >
      <p class="text-center text-xs mt-5 mb-5 w-fit">
        Enter an e-mail address you used to register your Multiples account to
        initiate password recovery process.
      </p>

      <div class="flex flex-col w-full mb-5">
        <label for="username">Email</label>
        <input
          id="username"
          type="email"
          placeholder="User email address"
          formControlName="username"
        />
        <small
          *ngIf="
            formGroup.controls['username'].touched &&
            formGroup.controls['username']?.hasError('required')
          "
        >
          Please provide an email address
        </small>
        <small
          *ngIf="
            formGroup.controls['username'].touched &&
            formGroup.controls['username']?.hasError('email')
          "
        >
          Please provide a valid email address
        </small>
      </div>

      <div class="flex flex-row justify-around links">
        <a routerLink="/authorization/login" class="underline">Back to login</a>
      </div>

      <button type="submit">
        Recover Account
      </button>
    </form>
  </div>
  <mul-footer></mul-footer>
</main>
