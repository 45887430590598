import { Injectable } from '@angular/core';
import { ApiService } from '../../common/service/api.service';
import { Observable, tap, throwError } from 'rxjs';
import { StoredGridServiceSavedResponse } from '../data/type/filter/stored-grid-saved-response.type';
import { ApiRoutes } from '../../common/data/enum/routes.enum';
import { StoredGridFilter } from '../data/type/filter/stored-grid.type';
import { FilterStorage } from './store/filter.storage';
import { DataSortingService } from './data-sorting.service';
import { PublicDataGridFilterService } from './public-data-grid-filter.service';
import { DataPaginationService } from './data-pagination.service';
import { GridMutatorService } from './mutator/grid-mutator.service';
import { SavedStoredGridStore } from './store/saved-stored-grid.store';
import { catchError } from 'rxjs/operators';
import { AuthUserStore } from './store/auth-user.store';

@Injectable({
  providedIn: 'root'
})
export class StoredGridFilterService {
  constructor(
    public savedStoredGridStore: SavedStoredGridStore,
    private readonly gridFilterService: PublicDataGridFilterService,
    private readonly dataSortingService: DataSortingService,
    private readonly dataPaginationService: DataPaginationService,
    private readonly gridMutatorService: GridMutatorService,
    private readonly filterStorage: FilterStorage,
    private readonly authUserStore: AuthUserStore,
    private readonly api: ApiService
  ) {
    this.authUserStore.user$.subscribe(val => {
      if (val?.accountId) this.findAllBySaved$().subscribe();
    });

    this.savedStoredGridStore.activeSavedStoredGrid$.subscribe(val => {
      if (val) this._applyStoredFilter(val);
    });
  }

  findAllBySaved$(): Observable<StoredGridServiceSavedResponse[]> {
    return this.api
      .httpGet$<StoredGridServiceSavedResponse[]>(ApiRoutes.STORED_GRID)
      .pipe(
        tap(val => {
          console.debug('LOAD USER SAVED STORED FILTERS');

          this.savedStoredGridStore.savedStoredGrids = val;
        })
      );
  }

  findById$(id: string): Observable<StoredGridFilter> {
    return this.api
      .httpGet$<StoredGridFilter>(ApiRoutes.STORED_GRID + `/${id}`)
      .pipe(
        tap(val => {
          console.debug('STORED-GRID');
          this._applyStoredFilter(val);
        })
      );
  }

  delete$(id: string) {
    return this.api
      .httpDelete$(ApiRoutes.STORED_GRID + `/${id}`)
      .pipe(catchError(err => throwError(() => err)));
  }

  save$(
    name?: string,
    saved?: boolean
  ): Observable<StoredGridServiceSavedResponse> {
    return this.api.httpPost$<StoredGridServiceSavedResponse>(
      ApiRoutes.STORED_GRID,
      {
        view: this.filterStorage.activeGridView,
        sorting: this.dataSortingService.gridSortStore.sort,
        page: this.dataPaginationService.dataPaginationStore.page,
        filter: this.gridFilterService.gridFilterStore.gridFilter,
        mutation: this.gridMutatorService.gridMutatorStorage.gridMutation,
        name,
        saved
      }
    );
  }

  update$(id: string): Observable<StoredGridServiceSavedResponse> {
    return this.api.httpPut$<StoredGridServiceSavedResponse>(
      ApiRoutes.STORED_GRID + `/${id}`,
      {
        view: this.filterStorage.activeGridView,
        sorting: this.dataSortingService.gridSortStore.sort,
        page: this.dataPaginationService.dataPaginationStore.page,
        filter: this.gridFilterService.gridFilterStore.gridFilter,
        mutation: this.gridMutatorService.gridMutatorStorage.gridMutation,
        saved: true
      }
    );
  }

  updateDefinition$(
    id: string,
    name: string
  ): Observable<StoredGridServiceSavedResponse> {
    return this.api.httpPut$<StoredGridServiceSavedResponse>(
      ApiRoutes.STORED_GRID_DEFINITION + `/${id}`,
      {
        name: name,
        saved: true
      }
    );
  }

  private _applyStoredFilter(val: StoredGridFilter) {
    this.gridFilterService.gridFilterStore.gridFilter = val.filter;
    this.gridMutatorService.gridMutatorStorage.gridMutation = val.mutation;
    this.dataSortingService.gridSortStore.sort = val.sorting;
    this.dataPaginationService.dataPaginationStore.page = val.page;
    this.filterStorage.activeGridView = val.view;
  }
}
