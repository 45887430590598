import {
  Component,
  effect,
  ElementRef,
  HostListener,
  ViewChild
} from '@angular/core';
import { StoredGridFilter } from '../../../data/type/filter/stored-grid.type';
import { StoredGridFilterService } from '../../../service/stored-grid-filter.service';
import { UiObserverStore } from '../../../../uicommon/service/ui-observer.store';
import { UiComponentRegisterEnum } from '../../../../uicommon/data/enum/uiComponentRegister.enum';
import { AuthorizationService } from '../../../../common/service/authorization.service';

@Component({
  selector: 'mul-stored-grid-select',
  templateUrl: './stored-grid-select.component.html'
})
export class StoredGridSelectComponent {
  @ViewChild('uiContainer') uiContainer: ElementRef<
    HTMLDivElement
  > = {} as ElementRef<HTMLDivElement>;

  displaySavedSearchesPanel = false;
  datasource: StoredGridFilter[] = [];
  selectedItem: StoredGridFilter | undefined = undefined;
  savedSearchesActionState = false;

  constructor(
    private readonly storedGridFilterService: StoredGridFilterService,
    public readonly authorizationService: AuthorizationService,
    private readonly uiObserverService: UiObserverStore
  ) {
    this.storedGridFilterService.savedStoredGridStore.savedStoredGrid$.subscribe(
      val => {
        this.datasource = val;

        if (this.selectedItem) {
          this.selectedItem = this.datasource.find(
            it => it.id === this.selectedItem!.id
          );
        }

        this.savedSearchesActionState = false;
      }
    );

    this.storedGridFilterService.savedStoredGridStore.activeSavedStoredGrid$.subscribe(
      val => {
        this.selectedItem = val;

        this.savedSearchesActionState = false;
      }
    );

    effect(() => {
      if (
        this.uiObserverService.uiFocusObserver !==
        UiComponentRegisterEnum.SELECT_SAVED_SEARCH
      ) {
        this.displaySavedSearchesPanel = false;
      }
    });
  }

  @HostListener('document:mousedown', ['$event.target'])
  onGlobalClick(target: HTMLElement): void {
    if (!this.uiContainer?.nativeElement.contains(target)) {
      this.close();
    }
  }

  toggle() {
    this.displaySavedSearchesPanel = !this.displaySavedSearchesPanel;

    if (this.displaySavedSearchesPanel) {
      this.uiObserverService.uiFocusObserver =
        UiComponentRegisterEnum.SELECT_SAVED_SEARCH;
    }
  }

  load(storedGridFilter: StoredGridFilter) {
    this.displaySavedSearchesPanel = false;
    this.savedSearchesActionState = true;
    this.storedGridFilterService.savedStoredGridStore.activeSavedStoredGrid = storedGridFilter;
  }

  close() {
    this.displaySavedSearchesPanel = false;
  }
}
