<div class="flex justify-evenly" *ngIf="gridViews.length > 1">
  <button
    *ngFor="let grid of gridViews"
    class="relative {{ activeGridView === grid.id ? 'active' : '' }}"
    (click)="changeView(grid.id)"
    [disabled]="loading === grid.id"
  >
    <ngx-spinner
      [fullScreen]="false"
      bdColor="transparent"
      color="#9ca3af"
      name="view-{{ grid.id }}"
      size="small"
      type="ball-clip-rotate"
    ></ngx-spinner>
    <span class="{{ loading === grid.id ? 'text-transparent' : '' }}">{{
      grid.name
    }}</span>
  </button>
</div>
