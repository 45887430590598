import { Component } from '@angular/core';
import { AuthorizationService } from '../../../common/service/authorization.service';

@Component({
  selector: 'mul-data-sheet-actions',
  templateUrl: './data-sheet-actions.component.html'
})
export class DataSheetActionsComponent {
  constructor(protected readonly authorizationService: AuthorizationService) {}
}
