<div class="stored-grid-item">
  <div class="flex flex-1">
    <span class="name" *ngIf="!editMode" (click)="load()">{{ item.name }}</span>
    <form *ngIf="editMode">
      <i class="fa-regular fa-keyboard"></i>
      <input
        value="{{ item.name }}"
        type="text"
        placeholder="Rename search to.."
        #nameInput
      />
      <button
        type="button"
        (click)="update()"
        *ngIf="editMode"
        aria-label="Update saved search name"
      >
        <i class="fa-regular fa-floppy-disk"></i>
      </button>
      <button
        type="button"
        (click)="editMode = false"
        *ngIf="editMode"
        aria-label="Cancel saved search edition"
      >
        <i class="fa-solid fa-xmark"></i>
      </button>
    </form>
  </div>
  <div class="flex justify-end items-center">
    <button
      (click)="editMode = true"
      *ngIf="!editMode"
      aria-label="Modify saved search name"
    >
      <i class="fa-regular fa-pen-to-square"></i>
    </button>
    <button
      (click)="deleteDialog()"
      *ngIf="!editMode"
      aria-label="Delete saved search"
    >
      <i class="fa-regular fa-trash-can"></i>
    </button>
  </div>
</div>
