import { Component } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DarkModeService } from 'angular-dark-mode';
import { Observable } from 'rxjs';
import { AuthorizationService } from './common/service/authorization.service';

@Component({
  selector: 'mul-app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  darkMode$: Observable<boolean> = this.darkModeService.darkMode$;

  mobile = false;

  constructor(
    private authService: AuthService,
    private authorizationService: AuthorizationService,
    private spinner: NgxSpinnerService,
    private darkModeService: DarkModeService
  ) {
    if (window.screen.width <= 750 || window.screen.height <= 500) {
      this.mobile = true;
    }

    if (this.authorizationService.isEmbeddedAuth()) {
      this.darkModeService.disable();
    }

    this.authService.autoLogin();
    this.spinner?.show('global');
  }
}
