<div class="dropdown-search flex flex-col" (keydown.escape)="exit()">
  <div class="flex flex-col items-center justify-center">
    <h2 *ngIf="label" class="flex items-center">
      {{ label
      }}<i *ngIf="tooltip" class="fa-solid fa-circle-info ml-1 peer"> </i>
      <div class="tooltip-container peer-hover:visible peer-hover:opacity-100">
        <div *ngIf="tooltip" class="tooltip">
          <i class="fa-solid fa-caret-left"></i>
          {{ tooltip }}
        </div>
      </div>
    </h2>
    <form [formGroup]="componentForm">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input
        type="text"
        id="formField"
        formControlName="formField"
        placeholder="{{ placeholder }}"
        autocomplete="off"
        (click)="open()"
      />
      <i *ngIf="componentState" class="fa-solid fa-xmark" (click)="exit()"> </i>
    </form>

    <div class="relative" #uiContainer (mouseleave)="exit()">
      <div class="dropdown-layer {{ !componentState ? 'hidden' : '' }}">
        <div
          class="flex justify-center items-center"
          *ngIf="data?.length === 0"
        >
          <span>No matching results</span>
        </div>

        <div *ngIf="multiLevel">
          <div class="mb-2" *ngFor="let item of data">
            <span (click)="addItem(item.index)">
              {{ item.label }}
            </span>
            <div
              class="flex items-center mb-1"
              *ngFor="let child of item.children"
            >
              <input
                id="{{ child.index }}"
                type="checkbox"
                value="{{ child.index }}"
                (click)="addItem(child.index)"
                [checked]="isSelected(child.index)"
              />

              <label for="{{ child.index }}">
                {{ child.label }}
              </label>
            </div>
          </div>
        </div>

        <div *ngIf="!multiLevel">
          <div class="flex align-middle mb-1" *ngFor="let item of data">
            <input
              id="{{ item.index }}"
              type="checkbox"
              value="{{ item.index }}"
              (click)="addItem(item.index)"
              [checked]="isSelected(item.index)"
            />

            <label for="{{ item.index }}">
              {{ item.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap" *ngIf="selection.length > 0">
    <div
      *ngFor="let item of selection; let i = index"
      [hidden]="i >= displayCount && !displayAllSelected"
    >
      <span class="pill">
        {{ item.label }}
        <button type="button" (click)="removeItem(item.index)">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </span>
    </div>
  </div>

  <div class="flex justify-end">
    <span
      class="extra"
      (click)="displayAllSelected = !displayAllSelected"
      [hidden]="selection.length <= displayCount"
    >
      <div [hidden]="displayAllSelected">
        +{{ selection.length - displayCount }}
      </div>
      <div [hidden]="!displayAllSelected">
        -{{ selection.length - displayCount }}
      </div>
    </span>
  </div>
</div>
