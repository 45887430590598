import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VerticalService } from '../vertical.service';
import { ResolveFn } from '@angular/router';
import { UIOptionType } from '../../data/type/filter/UIOption.type';

@Injectable({ providedIn: 'root' })
export class VerticalFilterResolverService {
  constructor(private verticalService: VerticalService) {}

  resolve() {
    return this.verticalService.getAll$();
  }
}

export const verticalFilterResolver: ResolveFn<Observable<
  UIOptionType[]
>> = () => inject(VerticalFilterResolverService).resolve();
