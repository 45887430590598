import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationUserComponent } from './components/navigation-user/navigation-user.component';
import { DarkModeSwitchComponent } from '../uicommon/components/dark-mode-switch/dark-mode-switch.component';
import { NavigationService } from './service/navigation.service';
import { NavigationStore } from './service/store/navigation.store';

@NgModule({
  declarations: [NavigationUserComponent],
  providers: [NavigationService, NavigationStore],
  exports: [NavigationUserComponent],
  imports: [CommonModule, DarkModeSwitchComponent]
})
export class NavigationModule {}
