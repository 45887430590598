import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Sort, SortFactory } from '../../data/type/grid/sort.type';

@Injectable({
  providedIn: 'root'
})
export class GridSortStore {
  private _sort: BehaviorSubject<Sort> = new BehaviorSubject<Sort>(
    SortFactory.generate()
  );

  private _trigger: Subject<boolean> = new Subject<boolean>();

  readonly sort$ = this._sort.asObservable();

  readonly trigger$ = this._trigger.asObservable();

  get sort() {
    return this._sort.getValue();
  }

  set sort(val: Sort) {
    this._sort.next(val);
  }

  set trigger(val: boolean) {
    this._trigger.next(val);
  }
}
