import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GridRecord } from '../../data/type/grid/grid-record.type';
import { GridHeader } from '../../data/type/grid/grid-header.type';
import { SummaryMetricEnum } from '../../data/enum/grid/summary-metric.enum';
import { GridData } from '../../data/type/organization/grid-data.type';
import { FieldMap } from '../../data/type/organization/field-map.type';

@Injectable({
  providedIn: 'root'
})
export class DataSheetStore {
  private _gridRecord: BehaviorSubject<GridRecord | null> = new BehaviorSubject<GridRecord | null>(
    null
  );

  private _gridData: BehaviorSubject<GridData[]> = new BehaviorSubject<
    GridData[]
  >([]);

  private _gridDisplayData: BehaviorSubject<GridData[]> = new BehaviorSubject<
    GridData[]
  >([]);

  private _gridDisplayPageData: BehaviorSubject<
    GridData[]
  > = new BehaviorSubject<GridData[]>([]);

  private _gridHeader: BehaviorSubject<
    GridHeader[] | null
  > = new BehaviorSubject<GridHeader[] | null>(null);

  private _summaryMetric: BehaviorSubject<
    SummaryMetricEnum
  > = new BehaviorSubject<SummaryMetricEnum>(SummaryMetricEnum.MEDIAN);

  private _recordCount: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  private _fieldMap: BehaviorSubject<FieldMap> = new BehaviorSubject<FieldMap>(
    new Map()
  );

  readonly gridRecord$ = this._gridRecord.asObservable();

  readonly gridData$ = this._gridData.asObservable();

  readonly gridDisplayData$ = this._gridDisplayData.asObservable();

  readonly gridDisplayPageData$ = this._gridDisplayPageData.asObservable();

  readonly gridHeader$ = this._gridHeader.asObservable();

  readonly summaryMetric$ = this._summaryMetric.asObservable();

  readonly recordCount$ = this._recordCount.asObservable();

  readonly fieldMap$ = this._fieldMap.asObservable();

  get gridRecord(): GridRecord | null {
    return this._gridRecord.getValue();
  }

  set gridRecord(val: GridRecord | null) {
    this._gridRecord.next(val);
  }

  get gridData(): GridData[] {
    return this._gridData.getValue();
  }

  set gridData(val: GridData[]) {
    this._gridData.next(val);
  }

  get gridDisplayData(): GridData[] {
    return this._gridDisplayData.getValue();
  }

  set gridDisplayData(val: GridData[]) {
    this._gridDisplayData.next(val);
  }

  get gridDisplayPageData(): GridData[] {
    return this._gridDisplayPageData.getValue();
  }

  set gridDisplayPageData(val: GridData[]) {
    this._gridDisplayPageData.next(val);
  }

  get gridHeader(): GridHeader[] | null {
    return this._gridHeader.getValue();
  }

  set gridHeader(val: GridHeader[] | null) {
    this._gridHeader.next(val);
  }

  get summaryMetric(): SummaryMetricEnum {
    return this._summaryMetric.getValue();
  }

  set summaryMetric(val: SummaryMetricEnum) {
    this._summaryMetric.next(val);
  }

  get recordCount(): number {
    return this._recordCount.getValue();
  }

  set recordCount(val: number) {
    this._recordCount.next(val);
  }

  get fieldMap(): FieldMap {
    return this._fieldMap.getValue();
  }

  set fieldMap(val: FieldMap) {
    this._fieldMap.next(val);
  }
}
