import { RoleEnum } from '../enum/role.enum';
import { AuthorityEnum } from '../enum/authority.enum';
import { AuthUser } from '../../../auth/data/entity/auth-user.entity';
import { AuthModeEnum } from '../enum/auth-mode.enum';
import { UserOptionsType } from '../../../auth/data/type/user-options.type';
import { EmbeddedOptionsType } from '../../../auth/data/type/embedded-options.type';
import { SubscriptionType } from '../../../subscription/data/type/subscription.type';

export class User {
  public accountId: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public username: string;
  public roles: RoleEnum[];
  public authorities: AuthorityEnum[];
  public authMode: AuthModeEnum;
  public options: UserOptionsType | undefined;
  public subscription: SubscriptionType | undefined;
  public embeddedOptions: EmbeddedOptionsType | undefined;

  constructor(
    accountId?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    username?: string,
    roles?: RoleEnum[],
    authorities?: AuthorityEnum[],
    authMode?: AuthModeEnum,
    options?: UserOptionsType,
    subscription?: SubscriptionType,
    embeddedOptions?: EmbeddedOptionsType
  ) {
    this.accountId = accountId ? accountId : '';
    this.firstName = firstName ? firstName : '';
    this.lastName = lastName ? lastName : '';
    this.email = email ? email : '';
    this.username = username ? username : '';
    this.roles = roles ? roles : [];
    this.authorities = authorities ? authorities : [];
    this.authMode = authMode ? authMode : AuthModeEnum.standard;
    this.options = options ? options : undefined;
    this.subscription = subscription ? subscription : undefined;
    this.embeddedOptions = embeddedOptions ? embeddedOptions : undefined;
  }

  static fromAuthUser(authUser: AuthUser) {
    return new User(
      authUser.accountId,
      authUser.firstName,
      authUser.lastName,
      authUser.email,
      authUser.username,
      authUser.roles,
      authUser.authorities,
      authUser.authMode,
      authUser.options,
      authUser.subscription,
      authUser.embeddedOptions
    );
  }
}
