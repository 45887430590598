<button (click)="export()" [disabled]="downloadActionState" class="relative">
  <i class="fa-solid fa-download"></i>
  <ngx-spinner
    name="download"
    bdColor="transparent"
    size="small"
    color="#000"
    type="ball-clip-rotate"
    [fullScreen]="false"
  ></ngx-spinner>
  <label class="{{ downloadActionState ? 'text-transparent' : '' }}">
    Download data
  </label>
</button>
