import { Injectable } from '@angular/core';
import { ApiService } from '../../common/service/api.service';
import { ApiRoutes } from '../../common/data/enum/routes.enum';
import { SubscriptionSessionType } from '../data/type/subscription-session.type';
import { SubscriptionCheckOutType } from '../data/type/subscription-checkout.type';
import { AccountSubscriptionType } from '../data/type/account-subscription.type';
import { AuthUser } from '../../auth/data/entity/auth-user.entity';
import { SubscriptionType } from '../data/type/subscription.type';
import { SubscriptionStore } from '../../data-sheet/service/store/subscription.store';
import { CustomerSessionType } from '../data/type/customer-session.type';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(
    private api: ApiService,
    private subscriptionStore: SubscriptionStore
  ) {}

  createCheckoutSession(data: SubscriptionCheckOutType) {
    return this.api.httpPost$<SubscriptionSessionType>(
      ApiRoutes.SUBSCRIPTION_CHECKOUT,
      data
    );
  }

  updateAccountSubscription(data: AccountSubscriptionType) {
    return this.api.httpPut$<AuthUser>(ApiRoutes.ACCOUNT_SUBSCRIPTION, data);
  }

  createAccountSubscription(priceId: string) {
    return this.api.httpPost$<AuthUser>(ApiRoutes.SUBSCRIPTION, { priceId });
  }

  createCustomerSession() {
    return this.api.httpPost$<CustomerSessionType>(
      ApiRoutes.CUSTOMER_SESSION,
      {}
    );
  }

  handleSubscriptionChange(val: SubscriptionType | undefined | null) {
    const currentSubscription = this.subscriptionStore.subscription;

    if (
      val === null ||
      currentSubscription?.subscriptionStatus !== val?.subscriptionStatus
    ) {
      this.subscriptionStore.subscription = val;
    }
  }
}
