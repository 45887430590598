import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  Mutation,
  MutationFactory
} from '../../data/type/mutator/data-mutator.type';

@Injectable({
  providedIn: 'root'
})
export class GridMutatorStore {
  private _gridMutation: BehaviorSubject<Mutation> = new BehaviorSubject<
    Mutation
  >(MutationFactory.generate());

  readonly gridMutation$ = this._gridMutation.asObservable();

  set statusOff(val: string[]) {
    this.gridMutation = { ...this.gridMutation, statusOff: val };
  }

  get statusOff() {
    return this.gridMutation.statusOff;
  }

  set gridMutation(val: Mutation) {
    this._gridMutation.next(val);
  }

  get gridMutation() {
    return this._gridMutation.getValue();
  }
}
