import { Component, Input, OnInit } from '@angular/core';
import { GridData } from '../../../../data/type/organization/grid-data.type';
import { ColumnField } from '../../../../data/type/grid/grid-header.type';
import { DataCellService } from '../../../../service/data-cell.service';

@Component({
  selector: 'mul-cell-year',
  templateUrl: './cell-year.component.html'
})
export class CellYearComponent implements OnInit {
  @Input() record!: GridData;
  @Input() field!: ColumnField;

  public recordElement = '';

  constructor(private columnFieldServiceService: DataCellService) {}

  ngOnInit(): void {
    this.recordElement = <string>this.record[this.field.ref];
  }
}
