import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  ColumnField,
  ColumnSubheader,
  GridHeader
} from '../data/type/grid/grid-header.type';
import { formatNumber } from '@angular/common';
import { FieldMap } from '../data/type/organization/field-map.type';
import { ColumnFieldTypeWidthEnum } from '../data/enum/grid/column-field-type-width.enum';
import { ColumnFieldUnitEnum } from '../data/enum/grid/column-field-unit.enum';

@Injectable({
  providedIn: 'root'
})
export class DataCellService {
  widthAdjustment = 5;

  enumTypeWidth: typeof ColumnFieldTypeWidthEnum = ColumnFieldTypeWidthEnum;

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  formatNumber(
    vle: number | null | undefined,
    maxDecimals = 1,
    postfix = '%'
  ): string {
    const minDecimals = maxDecimals > 0 ? maxDecimals : 0;
    if (vle != null) {
      const num = formatNumber(
        Math.abs(vle),
        this.locale,
        `1.${minDecimals}-${maxDecimals}`
      );

      return vle > 0 ? num + postfix : `(${num}${postfix})`;
    }

    return 'n/a';
  }

  setCellWidth(headers: GridHeader[]) {
    headers.forEach((header: GridHeader) => {
      header.header.width = this._setHeaderWidth(header);

      header.fields?.forEach((field: ColumnField) => {
        field.width = field.type
          ? ColumnFieldTypeWidthEnum[field.type]
          : ColumnFieldTypeWidthEnum.NUMBER;

        if (header.fields.length == 1) {
          field.width =
            header.header.width > field.width
              ? header.header.width
              : field.width;
        }
      });

      let subHeaderFieldsTotalWidth = 0;
      let subHeaderFieldsTotalCount = 0;
      header.subheaders?.forEach((subheader: ColumnSubheader) => {
        subheader.width = this._setSubHeaderWidth(subheader);

        subheader.fields?.forEach((field: ColumnField) => {
          field.width = field.type
            ? ColumnFieldTypeWidthEnum[field.type]
            : ColumnFieldTypeWidthEnum.NUMBER;
          field.width =
            subheader.width > field.width ? subheader.width : field.width;

          if (header.subheaders.length == 1) {
            field.width =
              header.header.width > field.width
                ? header.header.width
                : field.width;
          }

          subHeaderFieldsTotalWidth += field.width;
          ++subHeaderFieldsTotalCount;
        });
      });

      if (
        subHeaderFieldsTotalWidth < header.header.width &&
        subHeaderFieldsTotalWidth > 0 &&
        !header.hidden
      ) {
        const headerStandardUnitWidth =
          header.header.width / subHeaderFieldsTotalCount;

        header.subheaders?.forEach((subheader: ColumnSubheader) => {
          subheader.width =
            subheader.width < headerStandardUnitWidth
              ? headerStandardUnitWidth
              : subheader.width + this.widthAdjustment;

          subheader.fields?.forEach((field: ColumnField) => {
            field.width =
              field.width < headerStandardUnitWidth
                ? headerStandardUnitWidth
                : field.width + this.widthAdjustment;
          });
        });
      }
    });
  }

  formatCell(
    recordElement: number,
    unit: ColumnFieldUnitEnum,
    decimals = 0
  ): string {
    let postFix = '';

    if (unit == ColumnFieldUnitEnum.MULTIPLE) {
      postFix = 'x';
      decimals = 1;
    } else if (unit == ColumnFieldUnitEnum.PERCENTAGE) {
      postFix = '%';
    }
    return (recordElement < 0 && unit == ColumnFieldUnitEnum.MULTIPLE) ||
      (recordElement > 1000 && unit == ColumnFieldUnitEnum.PERCENTAGE)
      ? 'n/m'
      : this.formatNumber(<number>recordElement, decimals, postFix);
  }

  setFilterRefs(headers: GridHeader[]): FieldMap {
    const fieldMap: FieldMap = new Map();
    const fields: ColumnField[] = headers.flatMap(it =>
      it.fields.concat(it.subheaders.flatMap(it => it.fields))
    );

    fields.forEach(it =>
      fieldMap.set(it.field + (!isNaN(it.index) ? it.index : ''), it.ref)
    );

    return fieldMap;
  }

  private _setHeaderWidth(header: GridHeader): number {
    let width = 0;
    let labelWidth = 0;

    header.header.label.split('|').forEach(it => {
      const pixelWidth = it.length * ColumnFieldTypeWidthEnum.CHAR;
      labelWidth = labelWidth > pixelWidth ? labelWidth : pixelWidth;
    });

    header.fields?.forEach(field => {
      width += field.type
        ? this.enumTypeWidth[field.type]
        : this.enumTypeWidth.NUMBER;
    });

    return labelWidth > width ? labelWidth : width;
  }

  private _setSubHeaderWidth(subheader: ColumnSubheader): number {
    let width = 0;
    const labelWidth = subheader.label.length * ColumnFieldTypeWidthEnum.CHAR;

    width += subheader.fields[0].type
      ? this.enumTypeWidth[subheader.fields[0].type]
      : this.enumTypeWidth.NUMBER;

    return labelWidth > width ? labelWidth : width;
  }
}
