<form [formGroup]="filterForm">
  <div class="filter form">
    <h2>Date</h2>
    <div class="flex">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            (keyup)="onFormItemChange()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="dateMin"
            id="dateMin"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            for="revenueMin"
            >from</label
          >
        </div>
      </div>
      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            (keyup)="onFormItemChange()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="dateMax"
            id="dateMax"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            for="revenueMax"
            >to</label
          >
        </div>
      </div>
    </div>
  </div>
  <div class="filter form">
    <h2>Valuation</h2>
    <div class="flex flex-col mt-3 mb-6">
      <h3>Enterprise Value ($M)</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evMin"
              id="evMin"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="revenueMin"
              >min</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evMax"
              id="evMax"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="revenueMax"
              >max</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col mt-3 mb-6">
      <h3>Deal Size ($M)</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="dealSizeMin"
              id="dealSizeMin"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="revenueMin"
              >min</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="dealSizeMax"
              id="dealSizeMax"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="revenueMax"
              >max</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="filter form">
    <h2>Multiples</h2>

    <div class="flex flex-col mt-3 mb-6">
      <h3>EV/Revenue</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evRevenueMin"
              id="evRevenueMin"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="evRevenueMin"
              >min</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evRevenueMax"
              id="evRevenueMax"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="evRevenueMax"
              >max</label
            >
          </div>
        </div>
      </div>
      <mul-switch
        (dataMutationEventEmitter)="setRevenueExcludeNonReportedItems($event)"
        [value]="filterSelection.evRevenue?.excludeNonReportedItems || false"
        label="Exclude companies without reported revenue"
      ></mul-switch>
    </div>
    <div class="flex flex-col mt-3 mb-6">
      <h3>EV/EBITDA</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evEbitdaMin"
              id="evEbitdaMin"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="evEbitdaMin"
              >min</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="evEbitdaMax"
              id="evEbitdaMax"
              placeholder=" "
              type="text"
            />
            <label
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              for="evEbitdaMax"
              >max</label
            >
          </div>
        </div>
      </div>
      <mul-switch
        (dataMutationEventEmitter)="setEbitdaExcludeNonReportedItems($event)"
        [value]="filterSelection.evEbitda?.excludeNonReportedItems || false"
        label="Exclude companies without reported EBITDA"
      ></mul-switch>
    </div>
  </div>
  <div class="filter">
    <div class="flex flex-col justify-center">
      <h2>Deal Type</h2>
      <div class="mb-2">
        <mul-switch
          (dataMutationEventEmitter)="setStrategicMA($event)"
          [value]="filterSelection.strategicMA || false"
          label="Strategy M&A"
        ></mul-switch>
      </div>
      <div class="mb-2">
        <mul-switch
          (dataMutationEventEmitter)="onFinancialBuyOutChange($event)"
          label="Financial buyout"
        ></mul-switch>
        <div class="switch-block flex flex-col ml-5">
          <mul-switch
            (dataMutationEventEmitter)="setIsPrimaryBuyOut($event)"
            [value]="filterSelection.isPrimaryBuyOut || false"
            label="Primary buyout"
          ></mul-switch>
          <mul-switch
            (dataMutationEventEmitter)="setIsPortfolioAddOn($event)"
            [value]="filterSelection.isPortfolioAddOn || false"
            label="Portfolio add-on"
          ></mul-switch>
        </div>
      </div>
    </div>
  </div>
</form>
