export enum ColumnFieldTypeWidthEnum {
  SWITCH = 40,
  IMAGE = 30,
  ICON = 45,
  LIST = 210,
  STRING = 160,
  SHORT_STRING = 50,
  NUMBER = 50,
  YEAR = 50,
  DECIMAL = 50,
  PERCENTAGE = 50,
  LONG_NUMBER = 70,
  DEFAULT = 50,
  CHAR = 10,
  TEXT = 250
}
