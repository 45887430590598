import { Injectable } from '@angular/core';
import { SignUpType } from '../data/type/sign-up.type';
import { ApiService } from '../../common/service/api.service';
import { AuthResponse } from '../data/type/auth.response.type';
import { ApiRoutes } from '../../common/data/enum/routes.enum';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {
  constructor(private api: ApiService) {}

  signUp(data: SignUpType) {
    return this.api.httpPost$<AuthResponse>(
      ApiRoutes.AUTHORIZATION_SIGN_UP,
      data
    );
  }
}
