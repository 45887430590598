import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'mul-mobile-notice',
  templateUrl: './mobile-notice.component.html'
})
export class MobileNoticeComponent {
  protected readonly env = environment;
}
