import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { exhaustMap, Observable, take } from 'rxjs';
import { AuthUserStore } from '../../../data-sheet/service/store/auth-user.store';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authUserStore: AuthUserStore) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authUserStore.user$.pipe(
      take(1),
      exhaustMap(user => {
        const isLoggedIn = user && user.accessToken;
        const isApiUrl = req.url.includes('/api/');

        if (!isLoggedIn && !isApiUrl) {
          return next.handle(req);
        }

        const modifiedRequest = req.clone({
          setHeaders: {
            Authorization: `Bearer ${user?.accessToken}`
          }
        });

        return next.handle(modifiedRequest);
      })
    );
  }
}
