<div class="data-grid">
  <div class="flex-col row sticky-top">
    <div
      #toolbar
      class="{{
        authorizationService.isAuth() ? 'toolbar' : ''
      }} flex justify-between align-middle"
    >
      <mul-data-grid-view-selector
        class="views"
        *ngIf="authorizationService.isAuth()"
      ></mul-data-grid-view-selector>
      <mul-data-sheet-actions
        class="actions"
        *ngIf="authorizationService.isAuth()"
      ></mul-data-sheet-actions>
    </div>
    <div class="flex flex-row">
      <mul-header-cell
        *ngFor="let header of headers"
        [header]="header"
        [hidden]="header.hidden"
        class="cell header {{ header.ref }} {{
          header.sticky ? 'sticky-left' : ''
        }}"
      >
      </mul-header-cell>
    </div>
  </div>
  <div *ngFor="let record of datasource" class="row">
    <mul-data-cell
      *ngFor="let header of headers"
      [header]="header"
      [hidden]="header.hidden"
      [record]="record"
      class="cell data {{ header.sticky ? 'sticky-left' : '' }} {{
        isStatusOff(record) ? 'hidden' : ''
      }}"
    >
    </mul-data-cell>
  </div>
  <div class="flex flex-col row sticky-bottom">
    <div class="flex flex-row">
      <mul-summary-cell
        *ngFor="let header of headers; let i = index"
        [header]="header"
        [hidden]="header.hidden"
        class="cell summary {{ header.sticky ? 'sticky-left' : '' }}"
      >
        <span *ngIf="i === 0" class="foot-label">Median (all results)</span>
      </mul-summary-cell>
    </div>
    <mul-data-sheet-summary class="grid-summary"></mul-data-sheet-summary>
  </div>
</div>
