import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DarkModeService } from 'angular-dark-mode';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'mul-dark-mode-switch',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './dark-mode-switch.component.html'
})
export class DarkModeSwitchComponent {
  darkMode$: Observable<boolean> = this.darkModeService.darkMode$;

  constructor(private darkModeService: DarkModeService) {}

  onToggle(): void {
    this.darkModeService.toggle();
  }
}
