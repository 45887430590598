import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubscriptionCheckoutActionComponent } from './components/subscription-checkout-action/subscription-checkout-action.component';

const subscriptionRoutes: Routes = [
  {
    path: ':action/:accountId/:sessionId',
    component: SubscriptionCheckoutActionComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(subscriptionRoutes)],
  exports: [RouterModule]
})
export class SubscriptionRoutingModule {}
