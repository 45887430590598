import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { SnackBarService } from '../../../uicommon/service/snack-bar.service';
import { environment } from '../../../../environments/environment';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { AuthDomainInitializerService } from '../../../common/service/initializer/auth-domain-initializer.service';
import { SnackMessageStatusEnum } from '../../../uicommon/data/enum/snackMessageStatus.enum';

@Component({
  selector: 'mul-app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  public env = environment;

  formGroup: FormGroup = this.formBuilder.group({
    username: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]]
  });

  public passwordFieldVisibility = false;

  constructor(
    private authService: AuthService,
    private authorizationService: AuthorizationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private authDomainInitializerService: AuthDomainInitializerService
  ) {
    this.authDomainInitializerService.embeddedDomainAuth();

    this.authService.refreshLogIn().subscribe(() => {
      if (this.authorizationService.isAuth()) {
        this.router.navigate(['/data-sheet']);
      }
    });
  }

  onSubmitForm() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.spinner?.show('global');

    this.authService
      .login(
        this.formGroup.controls['username'].value,
        this.formGroup.controls['password'].value
      )
      .subscribe({
        complete: () => this.router.navigate(['/data-sheet']),
        error: () => {
          this.spinner?.hide('global');
          this.snackBarService.snack({
            message: 'Authentication failure',
            type: SnackMessageStatusEnum.ERROR
          });
        }
      });
  }
}
