export const environment = {
  production: true,
  sentry_env: 'production',
  app: 'Multiples',
  version: '2024.05.09.01',
  googleAnalyticsTrackingCode: 'G-VZ11984VBG',
  stripePublishableKey:
    'pk_live_51IB2V7Lnelk9LSDbouFgpzoCt3T1rcqQxyicVKKqnD42Xg3LjtL1BWC5S7z5CokmdkgJVGVfln8U8t1rTQxIg4vY00vGyWOWlh',
  app_url: 'https://app.multiples.finance',
  site_url: 'https://multiples.finance',
  default_price: 'price_1P0LCbLnelk9LSDbRd8q37f9',
  bo_url: 'https://admin.multiples.finance'
};
