import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '../../common/service/api.service';
import { Observable, Subscription, tap } from 'rxjs';
import { GridView } from '../data/type/grid/grid-view.type';
import { ApiRoutes } from '../../common/data/enum/routes.enum';
import { PublicDataGridFilterService } from './public-data-grid-filter.service';
import { GridTypeEnum } from '../data/enum/grid/grid-type.enum';
import { NavigationStore } from '../../navigation/service/store/navigation.store';
import { DataType } from '../../navigation/data/emun/data-type';

@Injectable({
  providedIn: 'root'
})
export class DataGridViewService implements OnDestroy {
  private navigationSubscription: Subscription = new Subscription();

  constructor(
    private navigationStore: NavigationStore,
    private gridFilterService: PublicDataGridFilterService,
    private api: ApiService
  ) {
    this.subscribeToNavigationChanges();
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  subscribeToNavigationChanges() {
    this.navigationSubscription = this.navigationStore.navigationState$.subscribe(
      val => {
        switch (val.dataType) {
          case DataType.PUBLIC:
            this.getAll$(GridTypeEnum.PUBLIC).subscribe();
            break;
          case DataType.DEALS:
            this.getAll$(GridTypeEnum.DEALS).subscribe();
            break;
          default:
            throw new Error(`Selected data ${val.dataType} type not supported`);
        }
      }
    );
  }

  getAll$(gridType?: GridTypeEnum): Observable<GridView[]> {
    return this.api
      .httpGet$<GridView[]>(
        `${ApiRoutes.GRID}/${gridType ? gridType : GridTypeEnum.PUBLIC}`
      )
      .pipe(
        tap(
          val => (this.gridFilterService.filterStorageService.gridViews = val)
        )
      );
  }
}
