import { BehaviorSubject } from 'rxjs';
import { SubscriptionType } from '../../../subscription/data/type/subscription.type';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionStore {
  private _subscription: BehaviorSubject<
    SubscriptionType | undefined | null
  > = new BehaviorSubject<SubscriptionType | undefined | null>(null);

  readonly subscription$ = this._subscription.asObservable();

  get subscription(): SubscriptionType | undefined | null {
    return this._subscription?.getValue();
  }

  set subscription(val: SubscriptionType | undefined | null) {
    this._subscription.next(val);
  }
}
