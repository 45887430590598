import { Injectable } from '@angular/core';
import { ApiService } from '../../common/service/api.service';
import { Observable, tap } from 'rxjs';
import { ApiRoutes } from '../../common/data/enum/routes.enum';
import { PublicDataGridFilterService } from './public-data-grid-filter.service';
import { UIOptionType } from '../data/type/filter/UIOption.type';

@Injectable({
  providedIn: 'root'
})
export class VerticalService {
  constructor(
    private api: ApiService,
    private gridFilterService: PublicDataGridFilterService
  ) {}

  getAll$(): Observable<UIOptionType[]> {
    return this.api
      .httpGet$<UIOptionType[]>(ApiRoutes.VERTICAL)
      .pipe(
        tap(
          val => (this.gridFilterService.filterStorageService.verticals = val)
        )
      );
  }
}
