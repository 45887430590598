import { RoleEnum } from '../../../common/data/enum/role.enum';
import { AuthorityEnum } from '../../../common/data/enum/authority.enum';
import { AuthModeEnum } from '../../../common/data/enum/auth-mode.enum';
import { UserOptionsType } from '../type/user-options.type';
import { EmbeddedOptionsType } from '../type/embedded-options.type';
import { SubscriptionType } from '../../../subscription/data/type/subscription.type';

export class AuthUser {
  public accountId: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public username: string;
  public roles: RoleEnum[];
  public authorities: AuthorityEnum[];
  public authMode: AuthModeEnum;
  public options: UserOptionsType | undefined;
  public embeddedOptions: EmbeddedOptionsType | undefined;
  public subscription: SubscriptionType | undefined;
  private readonly _accessToken: string;
  private readonly _expires: number;
  private readonly _accessDate: number;

  constructor(
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    username: string,
    expires: number,
    accessToken: string,
    roles: RoleEnum[],
    authorities: AuthorityEnum[],
    authMode?: AuthModeEnum,
    accessDate?: number,
    options?: UserOptionsType,
    embeddedOptions?: EmbeddedOptionsType,
    subscription?: SubscriptionType
  ) {
    this.accountId = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.username = username;
    this.roles = roles;
    this.authorities = authorities;
    this.authMode = authMode ? authMode : AuthModeEnum.standard;
    this._expires = expires;
    this._accessDate = accessDate ? accessDate : new Date().getTime();
    this._accessToken = accessToken;
    this.options = options ? options : undefined;
    this.embeddedOptions = embeddedOptions ? embeddedOptions : undefined;
    this.subscription = subscription ? subscription : undefined;
  }

  get accessToken(): string | null {
    if (
      !this._accessToken ||
      new Date().getTime() > this._accessDate + this._expires
    ) {
      return null;
    }

    return this._accessToken;
  }
}
