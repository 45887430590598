import { Injectable } from '@angular/core';
import { NavigationStore } from './store/navigation.store';
import { navItems } from '../data/const/nav-items';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private readonly navigationStore: NavigationStore) {
    this.navigationStore.navigation = navItems;
  }

  setNavigationState() {
    const navItem = navItems.find(it => it.default);

    if (navItem) {
      this.navigationStore.navigationState = navItem;
    }
  }
}
