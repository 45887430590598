import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatabaseService } from './services/database.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [DatabaseService]
})
export class StoreModule {}
