import { DataType } from '../emun/data-type';
import { NavigationItemType } from '../type/navigation-item.type';

export const navItems: NavigationItemType[] = [
  {
    label: 'Public Companies',
    dataType: DataType.PUBLIC,
    logo: 'fa-arrow-trend-up',
    status: true,
    default: true
  },
  {
    label: 'M&A / Buyouts',
    dataType: DataType.DEALS,
    logo: 'fa-handshake-simple',
    status: false,
    default: false
  },
  {
    label: 'IPOs / SPACs',
    dataType: DataType.IPO,
    logo: 'fa-hand-holding-dollar',
    status: false,
    default: false
  },
  {
    label: 'VC / Growth Rounds',
    dataType: DataType.GROWTH,
    logo: 'fa-seedling',
    status: false,
    default: false
  }
];
