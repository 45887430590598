<span
  *ngFor="let tag of recordElement; let i = index"
  [hidden]="i >= filterDisplayLimit || extend || tag === ''"
  class="pill"
>
  <div class="{{ recordElement.length > 1 ? 'short' : '' }}">{{ tag }}</div>
</span>
<span
  (click)="extend = !extend"
  (mouseover)="extend = !extend"
  [hidden]="recordElement.length <= filterDisplayLimit || extend"
  class="extra"
>
  +{{ recordElement.length - filterDisplayLimit }}
</span>

<div
  (click)="extend = !extend"
  (mouseleave)="extend = !extend"
  [hidden]="(!extend && recordElement.length > 1) || recordElement.length === 1"
  class="list extended"
>
  <span *ngFor="let tag of recordElement" class="pill">
    {{ tag }}
  </span>
</div>
