export type Mutation = {
  statusOff: string[];
};

export class MutationFactory {
  static generate() {
    return <Mutation>{
      statusOff: []
    };
  }
}
