<button
  (click)="share()"
  [disabled]="shareActionState"
  *ngIf="this.authorizationService.isStandardAuth()"
>
  <i class="fa-solid fa-share-nodes"></i>
  <ngx-spinner
    name="share"
    bdColor="transparent"
    size="small"
    color="#000"
    type="ball-clip-rotate"
    [fullScreen]="false"
  ></ngx-spinner>
  <label>Share view</label>
</button>
