export type BuyerFilterType = {
  keywords: string[];
  organizationSelection: string[];
  includeOrganizations: boolean;
  conditionalIncludeOrganizations: boolean;
  geographySelection: string[];
  isPublic: boolean;
  isPrivate: boolean;
  isPrimaryBuyOut: boolean;
  isPortfolioAddOn: boolean;
};

export class BuyerFilterFactory {
  static generate() {
    return <BuyerFilterType>{
      keywords: [],
      organizationSelection: [],
      includeOrganizations: false,
      conditionalIncludeOrganizations: false,
      geographySelection: [],
      isPublic: false,
      isPrivate: false,
      isPrimaryBuyOut: false,
      isPortfolioAddOn: false
    };
  }
}
