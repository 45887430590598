<div
  class="{{ visible ? 'visible' : 'invisible' }} relative z-10 {{
    dialog.type?.toLowerCase()
  }}"
>
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div
    class="fixed inset-0 bg-primary-500 bg-opacity-75 transition-opacity"
  ></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
      >
        <div
          class="bg-primary-50 dark:bg-inverse-50 px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
        >
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full icon sm:mx-0 sm:h-10 sm:w-10"
            >
              <mul-icon-alert
                *ngIf="dialog.type === DialogStatusEnum.ALERT"
              ></mul-icon-alert>
              <mul-icon-error
                *ngIf="dialog.type === DialogStatusEnum.ERROR"
              ></mul-icon-error>
              <mul-icon-info
                *ngIf="dialog.type === DialogStatusEnum.INFO"
              ></mul-icon-info>
              <mul-icon-success
                *ngIf="dialog.type === DialogStatusEnum.SUCCESS"
              ></mul-icon-success>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                class="text-base font-semibold leading-6 text-primary-900 dark:text-inverse-900"
                id="modal-title"
              >
                {{ dialog.title }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-primary-500 dark:text-inverse-500">
                  {{ dialog.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-primary-100 dark:bg-inverse-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
        >
          <button
            (click)="do()"
            type="button"
            class="inline-flex w-full justify-center rounded action px-3 py-2 text-sm font-semibold text-primary-50 shadow-sm sm:ml-3 sm:w-auto"
          >
            {{ dialog.actionLabel }}
          </button>
          <button
            (click)="cancel()"
            type="button"
            class="mt-3 inline-flex w-full justify-center rounded bg-primary-50 dark:bg-inverse-50 px-3 py-2 text-sm font-semibold text-primary-900 dark:text-inverse-900 shadow-sm ring-1 ring-inset ring-primary-300 dark:ring-inverse-300 hover:bg-primary-50 dark:hover:bg-inverse-50 sm:mt-0 sm:w-auto"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
