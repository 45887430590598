import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterTriggerEvent {
  triggerFilter: Subject<boolean> = new Subject<boolean>();

  triggerFilter$ = this.triggerFilter.asObservable();
}
