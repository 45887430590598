<main class="h-screen flex flex-col items-center justify-center tracking-tight">
  <div class="auth mb-5" [style]="{ width: '22rem' }">
    <h1 class="flex flex-col items-center mb-5">
      <a href="{{ env.site_url }}"></a>
    </h1>

    <p>
      Multiples is currently only available on desktops and tablets.
      <a
        class="underline"
        href="mailto:?subject=Join Multiples - Free Public Comps Database for the Tech Ecosystem&body=Visit https://multiples.finance to join Multiples and get access to valuation multiples for 100+ technology sectors!"
      >
        Send yourself a link</a
      >
      to log in on a different device.
    </p>
  </div>
</main>
