import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../../environments/environment';
import { SubscriptionRoutingModule } from './subscription-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SubscriptionCheckoutActionComponent } from './components/subscription-checkout-action/subscription-checkout-action.component';
import { UpgradeActionComponent } from './components/upgrade-action/upgrade-action.component';
import { FooterComponent } from '../uicommon/components/footer/footer.component';

@NgModule({
  declarations: [SubscriptionCheckoutActionComponent, UpgradeActionComponent],
  exports: [UpgradeActionComponent],
  imports: [
    CommonModule,
    SubscriptionRoutingModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    ReactiveFormsModule,
    NgOptimizedImage,
    FooterComponent
  ]
})
export class SubscriptionModule {}
