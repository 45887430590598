import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UIOptionType } from '../../../data/type/filter/UIOption.type';
import { BuyerFilterType } from '../../../data/type/filter/deal/buyer-filter-type';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'mul-buyer-filter',
  templateUrl: './buyer-filter.component.html'
})
export class BuyerFilterComponent implements OnInit {
  @Output() dataMutationEventEmitter: EventEmitter<
    BuyerFilterType
  > = new EventEmitter();

  @Input() filterSelection: BuyerFilterType = {} as BuyerFilterType;

  @Input() geographyDataSet: UIOptionType[] = [];
  @Input() verticalDataSet: UIOptionType[] = [];
  @Input() buyerOrganizationsDataSet: UIOptionType[] = [];

  geographySelection: UIOptionType[] = [];
  buyerOrganizationSelection: UIOptionType[] = [];

  filterForm: FormGroup = this.formBuilder.group({
    keywords: ''
  });

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.geographySelection = this.geographyDataSet.filter(it =>
      this.filterSelection.geographySelection?.includes(it.ref)
    );

    this.buyerOrganizationSelection = this.buyerOrganizationsDataSet.filter(
      it => this.filterSelection.organizationSelection?.includes(it.ref)
    );

    this.filterForm
      .get('keywords')
      ?.patchValue(this.filterSelection.keywords?.join(', '));
  }

  setOrganizations($event: string[]) {
    this.filterSelection.organizationSelection = $event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setGeography($event: string[]) {
    this.filterSelection.geographySelection = $event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  onFormKeywordsItemChange() {
    const keywords: string = this.filterForm.get('keywords')?.value;

    this.filterSelection.keywords = keywords.length
      ? keywords.split(',').map(it => it.trim())
      : [];

    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setIncludeOrganizations(event: boolean) {
    this.filterSelection.includeOrganizations = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setConditionalIncludeOrganizations(event: boolean) {
    this.filterSelection.conditionalIncludeOrganizations = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setIsPublic(event: boolean) {
    this.filterSelection.isPublic = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setIsPrivate(event: boolean) {
    this.filterSelection.isPrivate = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setIsPrimaryBuyout(event: boolean) {
    this.filterSelection.isPrimaryBuyOut = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  setIsPortfolioAddOn(event: boolean) {
    this.filterSelection.isPortfolioAddOn = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  onPEInvestorChange(event: boolean) {
    this.filterSelection.isPrimaryBuyOut = event;
    this.filterSelection.isPortfolioAddOn = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }

  onStrategyAcquirerChange(event: boolean) {
    this.filterSelection.isPublic = event;
    this.filterSelection.isPrivate = event;
    this.dataMutationEventEmitter.emit(this.filterSelection);
  }
}
