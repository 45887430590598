import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlTree
} from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AppRoutes } from '../../data/enum/routes.enum';
import { AuthUserStore } from '../../../data-sheet/service/store/auth-user.store';

@Injectable({
  providedIn: 'root'
})
export class SharedViewGuardService {
  constructor(
    private readonly authUserStore: AuthUserStore,
    private readonly router: Router
  ) {}

  canActivate(storedGridFilterId: string) {
    return this.authUserStore.user$.pipe(
      take(1),
      map(user => {
        const isAuth = !!user;

        if (isAuth) {
          return true;
        }

        return this.router.createUrlTree([
          AppRoutes.DATA_SHEET_PUBLIC + storedGridFilterId
        ]);
      })
    );
  }
}

export const sharedViewGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> =>
  inject(SharedViewGuardService).canActivate(
    route.params['storedGridFilterId']
  );
