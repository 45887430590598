import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthorizationService } from '../common/service/authorization.service';
import { NavigationStore } from '../navigation/service/store/navigation.store';
import { DataType } from '../navigation/data/emun/data-type';
import { Subscription } from 'rxjs';

export const FILTER_WIDTH: WritableSignal<number> = signal(0);

@Component({
  selector: 'mul-data-sheet',
  templateUrl: './data-sheet.component.html'
})
export class DataSheetComponent implements AfterViewChecked, OnDestroy {
  protected readonly DataType = DataType;
  protected readonly env = environment;
  protected dataType: DataType = DataType.PUBLIC;

  @ViewChild('filter', { static: true }) filter: ElementRef<
    HTMLDivElement
  > = {} as ElementRef<HTMLDivElement>;

  private navigationStateSubscription: Subscription;

  constructor(
    public authorizationService: AuthorizationService,
    public navigationStore: NavigationStore
  ) {
    this.navigationStateSubscription = this.navigationStore.navigationState$.subscribe(
      val => {
        this.dataType = val.dataType;
      }
    );
  }

  ngOnDestroy(): void {
    this.navigationStateSubscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    FILTER_WIDTH.set(this.filter.nativeElement.clientWidth);
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }
}
