import { Component } from '@angular/core';
import { AuthorizationService } from '../../../../common/service/authorization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StoredGridFilterService } from '../../../service/stored-grid-filter.service';
import { SnackBarService } from '../../../../uicommon/service/snack-bar.service';
import { AccessPromoService } from '../../../../auth/services/access-promo.service';
import { SnackMessageStatusEnum } from '../../../../uicommon/data/enum/snackMessageStatus.enum';
import { PromoEnum } from '../../../../auth/data/enum/promo.enum';

@Component({
  selector: 'mul-share-data-action',
  templateUrl: './share-data-action.component.html'
})
export class ShareDataActionComponent {
  shareActionState = false;

  constructor(
    public readonly authorizationService: AuthorizationService,
    private readonly spinner: NgxSpinnerService,
    private readonly storedGridFilterService: StoredGridFilterService,
    private readonly snackBarService: SnackBarService,
    private readonly accessPromoService: AccessPromoService
  ) {}

  share() {
    if (!this.authorizationService.isSubscribed()) {
      this.accessPromoService.show(PromoEnum.SHARED);

      return;
    }

    this.spinner?.show('share');
    this.shareActionState = true;

    this.storedGridFilterService.save$().subscribe(data => {
      navigator.clipboard
        .writeText(window.location.host + '/data-sheet/shared/' + data.id)
        .then(() => {
          this.snackBarService.snack({
            type: SnackMessageStatusEnum.SUCCESS,
            message: `The current view has been copied to your clipboard. You can share it now with anyone as a live comps table.`
          });
        });

      this.spinner?.hide('share');
      this.shareActionState = false;
    });
  }
}
