<div class="flex items-center">
  <mul-stored-grid-select></mul-stored-grid-select>
  <mul-download-data-action></mul-download-data-action>
  <mul-share-data-action></mul-share-data-action>
  <mul-save-data-action></mul-save-data-action>
  <mul-upgrade-action
    id="top-bar-upgrade-action"
    *ngIf="!authorizationService.isSubscribed()"
    [label]="
      this.authorizationService.isTrialPeriodAllowed()
        ? 'Start 7-Day Trial'
        : 'Upgrade Plan'
    "
  ></mul-upgrade-action>
</div>
