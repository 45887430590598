import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StoredGridFilter } from '../../data/type/filter/stored-grid.type';

@Injectable({
  providedIn: 'root'
})
export class SavedStoredGridStore {
  private _savedStoredGrids: BehaviorSubject<
    StoredGridFilter[]
  > = new BehaviorSubject<StoredGridFilter[]>([]);

  private _activeSavedStoredGrid: BehaviorSubject<
    StoredGridFilter | undefined
  > = new BehaviorSubject<StoredGridFilter | undefined>(undefined);

  readonly savedStoredGrid$ = this._savedStoredGrids.asObservable();

  readonly activeSavedStoredGrid$ = this._activeSavedStoredGrid.asObservable();

  get savedStoredGrids() {
    return this._savedStoredGrids.getValue();
  }

  set savedStoredGrids(val: StoredGridFilter[]) {
    this._savedStoredGrids.next(val);
  }

  get activeSavedStoredGrid() {
    return this._activeSavedStoredGrid.getValue();
  }

  set activeSavedStoredGrid(val: StoredGridFilter | undefined) {
    this._activeSavedStoredGrid.next(val);
  }

  addToSavedStoredGrids(val: StoredGridFilter) {
    const savedStoredGrids = this.savedStoredGrids;
    savedStoredGrids.push(val);

    this.savedStoredGrids = savedStoredGrids;
  }

  updateSavedStoredGrids(val: StoredGridFilter) {
    const savedStoredGrids = this.savedStoredGrids.filter(
      it => it.id !== val.id
    );

    savedStoredGrids.push(val);

    this.savedStoredGrids = savedStoredGrids;
  }

  findActiveSavedStoreFilterById(id: string): StoredGridFilter | undefined {
    return this.savedStoredGrids.find(it => it.id == id);
  }
}
