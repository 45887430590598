import { Component, Input } from '@angular/core';
import { GridData } from '../../../../data/type/organization/grid-data.type';
import { ColumnField } from '../../../../data/type/grid/grid-header.type';
import { DataSheetStore } from '../../../../service/store/data-sheet.store';
import { FilterRefEnum } from '../../../../data/enum/grid/filter-ref.enum';
import { GridMutatorService } from '../../../../service/mutator/grid-mutator.service';

@Component({
  selector: 'mul-cell-switch',
  templateUrl: './cell-switch.component.html'
})
export class CellSwitchComponent {
  @Input() record!: GridData;
  @Input() field!: ColumnField;

  constructor(
    private readonly gridMutatorService: GridMutatorService,
    private readonly dataSheetStorageService: DataSheetStore
  ) {}

  setAction() {
    const skuRef = this.dataSheetStorageService.fieldMap.get(FilterRefEnum.SKU);
    const sku = skuRef ? <string>this.record[skuRef] : null;
    const value = !this.record[this.field.ref];

    if (sku) {
      const statusOff = new Set<string>(
        this.gridMutatorService.gridMutatorStorage.statusOff
      );

      if (value) {
        statusOff.delete(sku);
      } else {
        statusOff.add(sku);
      }

      this.gridMutatorService.gridMutatorStorage.statusOff = Array.from(
        statusOff
      );
    }
  }
}
