import { Injectable, signal, WritableSignal } from '@angular/core';
import { DialogType } from '../data/type/dialog.type';
import { DialogStatusEnum } from '../data/enum/dialogStatus.enum';

export const dialog: WritableSignal<DialogType> = signal({} as DialogType);

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  show(message: DialogType) {
    dialog.set({
      ...message,
      type: message.type ? message.type : DialogStatusEnum.INFO,
      status: message.status ? message.status : true
    });
  }
}
