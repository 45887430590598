import { Component, OnDestroy } from '@angular/core';
import { GridView } from '../../data/type/grid/grid-view.type';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { PublicDataGridFilterService } from '../../service/public-data-grid-filter.service';
import { AccessPromoService } from '../../../auth/services/access-promo.service';
import { PublicDataService } from '../../service/data-sheet/public-data/public-data.service';
import { Subscription } from 'rxjs';
import { PromoEnum } from '../../../auth/data/enum/promo.enum';

@Component({
  selector: 'mul-data-grid-view-selector',
  templateUrl: './data-grid-view-selector.component.html'
})
export class DataGridViewSelectorComponent implements OnDestroy {
  gridViews: GridView[] = [];
  activeGridView = '';
  loading: string | null = null;

  private gridViewsSubscriptions: Subscription = new Subscription();
  private activeGridViewSubscriptions: Subscription = new Subscription();

  constructor(
    private readonly dataSheetService: PublicDataService,
    private readonly gridFilterService: PublicDataGridFilterService,
    private readonly authorizationService: AuthorizationService,
    private readonly accessPromoService: AccessPromoService,
    private readonly spinner: NgxSpinnerService // private readonly dialog: MatDialog
  ) {
    this.gridViewsSubscriptions = this.gridFilterService.filterStorageService.gridViews$.subscribe(
      val => {
        this.gridViews = val;
      }
    );

    this.activeGridViewSubscriptions = this.gridFilterService.filterStorageService.activeGridView$.subscribe(
      val => {
        // if (!val) {
        //   val = this.gridFilterService.filterStorageService.gridViews[0].id;
        // }

        this.activeGridView = val;
      }
    );
  }

  ngOnDestroy(): void {
    this.activeGridViewSubscriptions.unsubscribe();
    this.gridViewsSubscriptions.unsubscribe();
  }

  changeView(id: string) {
    if (!this.authorizationService.isSubscribed()) {
      this.accessPromoService.show(PromoEnum.VIEWS);
      return;
    }
    this.loading = id;
    this.gridFilterService.filterStorageService.activeGridView = id;
    this.spinner?.show(`view-${id}`);
    this.dataSheetService.getAll$(id).subscribe({
      next: () => {
        this.spinner?.hide(`view-${id}`).then(() => {
          this.loading = null;
        });
      }
    });
  }
}
