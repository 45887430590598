<form [formGroup]="filterForm">
  <div class="filter">
    <div class="keyword flex flex-col items-center justify-center">
      <h2>Target Keywords<i class="fa-solid fa-circle-info ml-1"></i></h2>
      <form>
        <textarea
          id="keywords"
          formControlName="keywords"
          (keyup)="onFormKeywordsItemChange()"
          rows="4"
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Type in keywords only, e.g.&#10;&#10;defense technology, aerospace, military tech, govtech, data protection tools"
        ></textarea>
      </form>
    </div>
  </div>
  <mul-multi-select
    class="filter"
    label="Target Verticals"
    tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec dui nunc."
    componentId="target_verticals"
    placeholder="E.g. Music or Online Payments"
    [dataSet]="verticalDataSet"
    [selection]="verticalSelection"
    (dataMutationEventEmitter)="setVerticals($event)"
  ></mul-multi-select>
  <div class="filter">
    <mul-multi-select
      label="Target Names"
      tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod."
      componentId="target_name"
      placeholder="Start typing a name"
      [dataSet]="targetOrganizationDataSet"
      [selection]="targetOrganizationSelection"
      (dataMutationEventEmitter)="setOrganizations($event)"
    ></mul-multi-select>
    <mul-switch
      label="Include in results"
      [value]="filterSelection.includeOrganizations || false"
      (dataMutationEventEmitter)="setIncludeOrganizations($event)"
    ></mul-switch>
    <mul-switch
      label="Include ONLY if all other criteria are meet"
      [value]="filterSelection.conditionalIncludeOrganizations || true"
      (dataMutationEventEmitter)="setConditionalIncludeOrganizations($event)"
    ></mul-switch>
  </div>
  <mul-multi-select
    class="filter"
    label="Target Geography"
    tooltip="Lorem ipsum dolor sit amet, consectetur."
    componentId="target_geography"
    placeholder="E.g. Europe, Brazil or India"
    [dataSet]="geographyDataSet"
    [selection]="geographySelection"
    (dataMutationEventEmitter)="setGeography($event)"
  ></mul-multi-select>
  <div class="filter form">
    <h2>Target Financials</h2>

    <div class="flex flex-col mt-3 mb-6">
      <h3>Revenue ($M)</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              id="revenueMin"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="revenueMin"
              placeholder=" "
              type="text"
            />
            <label
              for="revenueMin"
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >min</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              id="revenueMax"
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="revenueMax"
              placeholder=" "
              type="text"
            />
            <label
              for="revenueMax"
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >max</label
            >
          </div>
        </div>
      </div>
      <mul-switch
        label="Exclude companies without reported revenue"
        [value]="filterSelection.revenue?.excludeNonReportedItems || false"
        (dataMutationEventEmitter)="setRevenueExcludeNonReportedItems($event)"
      ></mul-switch>
    </div>
    <div class="flex flex-col mt-3 mb-6">
      <h3>EBITDA ($M)</h3>
      <div class="flex">
        <div class="flex flex-col">
          <div class="relative float-label">
            <input
              (keyup)="onFormItemChange()"
              id="ebitdaMin"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="ebitdaMin"
              placeholder=" "
              type="text"
            />
            <label
              for="ebitdaMin"
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >min</label
            >
          </div>
        </div>
        <div class="flex flex-col ml-2">
          <div class="relative float-label">
            <input
              id="ebitdaMax"
              (keyup)="onFormItemChange()"
              class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
              formControlName="ebitdaMax"
              placeholder=" "
              type="text"
            />
            <label
              for="ebitdaMax"
              class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >max</label
            >
          </div>
        </div>
      </div>
      <mul-switch
        label="Exclude companies without reported EBITDA"
        [value]="filterSelection.ebitda?.excludeNonReportedItems || false"
        (dataMutationEventEmitter)="setEbitdaExcludeNonReportedItems($event)"
      ></mul-switch>
    </div>
  </div>
</form>
