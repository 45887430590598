import { Injectable, OnDestroy } from '@angular/core';
import { DataSheetStore } from './store/data-sheet.store';
import { FilterStorage } from './store/filter.storage';
import { DealDataFilterStore } from './store/deal-data-filter.store';
import { GridData } from '../data/type/organization/grid-data.type';
import { DealDataFilter } from '../data/type/filter/deal/deal-data-filter.type';
import { Subscription } from 'rxjs';
import { FilterRefEnum } from '../data/enum/grid/filter-ref.enum';
import { DataPaginationStore } from './store/data-pagination.store';
import { DataGridFilterToolsService } from './DataGridFilterTools.service';
import { TargetFilterType } from '../data/type/filter/deal/target-filter.type';
import { BuyerFilterType } from '../data/type/filter/deal/buyer-filter-type';
import { DealInfoFilterType } from '../data/type/filter/deal/deal-info-filter.type';

@Injectable({
  providedIn: 'root'
})
export class DealDataGridFilterService implements OnDestroy {
  private gridFilterSubscription: Subscription = new Subscription();

  constructor(
    public dealDataFilterStore: DealDataFilterStore,
    public dataSheetStorageService: DataSheetStore,
    public filterStorageService: FilterStorage,
    private dataPaginationStoreService: DataPaginationStore,
    private dataGridFilterToolsService: DataGridFilterToolsService
  ) {
    this.gridFilterSubscription = this.dealDataFilterStore.gridFilter$.subscribe(
      async val => {
        const data = this.dataSheetStorageService.gridData;

        if (data.length > 0) {
          console.debug('DEAL FILTERING: gridFilter$', val);
          await this.__processFilter(data, val);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.gridFilterSubscription.unsubscribe();
  }

  private async __processFilter(data: GridData[], filter: DealDataFilter) {
    data = this._processTargetFilter(data, filter.targetFilter);
    data = this._processBuyerFilter(data, filter.buyerFilter);
    data = this._processDealInfoFilter(data, filter.dealInfoFilter);

    this.dataSheetStorageService.gridDisplayData = data;
    this.dataPaginationStoreService.page = 1;
    this.dataPaginationStoreService.length = data.length;

    this.dealDataFilterStore.filterCount = this._getFilterCount();
  }

  private _processTargetFilter(data: GridData[], filter: TargetFilterType) {
    data = filter.keywords?.length
      ? this.dataGridFilterToolsService.filterByKeywords(
          data,
          filter.keywords,
          [
            FilterRefEnum.TARGET_DESCRIPTION,
            FilterRefEnum.TARGET_META,
            FilterRefEnum.TARGET_TAGS
          ]
        )
      : data;

    data = filter.verticalSelection?.length
      ? this.dataGridFilterToolsService.filterByCharacterSeparatedInnerField(
          data,
          filter.verticalSelection,
          FilterRefEnum.TARGET_TAGS
        )
      : data;

    if (filter.conditionalIncludeOrganizations) {
      data = filter.organizationSelection?.length
        ? this.dataGridFilterToolsService.filterByElements(
            data,
            filter.organizationSelection,
            FilterRefEnum.TARGET_ID
          )
        : data;
    }

    data = filter.geographySelection?.length
      ? this.dataGridFilterToolsService.filterByElements(
          data,
          filter.geographySelection,
          FilterRefEnum.TARGET_COUNTRY_CODE
        )
      : data;

    data =
      filter.ebitda?.min !== null || filter.ebitda?.max !== null
        ? this.dataGridFilterToolsService.filterByFinancialValueSet(
            data,
            {
              min: filter.ebitda?.min,
              max: filter.ebitda?.max
            },
            FilterRefEnum.TARGET_EBITDA
          )
        : data;

    data =
      filter.revenue?.min !== null || filter.revenue?.max !== null
        ? this.dataGridFilterToolsService.filterByFinancialValueSet(
            data,
            {
              min: filter.revenue?.min,
              max: filter.revenue?.max
            },
            FilterRefEnum.TARGET_REVENUE
          )
        : data;

    if (filter.includeOrganizations) {
      data = filter.organizationSelection?.length
        ? this.dataGridFilterToolsService.attachRows(
            this.dataSheetStorageService.gridData,
            data,
            filter.organizationSelection,
            FilterRefEnum.TARGET_ID
          )
        : data;
    }

    return data;
  }

  private _processBuyerFilter(data: GridData[], filter: BuyerFilterType) {
    data = filter.keywords?.length
      ? this.dataGridFilterToolsService.filterByKeywords(
          data,
          filter.keywords,
          [FilterRefEnum.BUYER_DESCRIPTION, FilterRefEnum.BUYER_META]
        )
      : data;

    if (filter.conditionalIncludeOrganizations) {
      data = filter.organizationSelection?.length
        ? this.dataGridFilterToolsService.filterByElements(
            data,
            filter.organizationSelection,
            FilterRefEnum.BUYER_ID
          )
        : data;
    }

    data = filter.geographySelection?.length
      ? this.dataGridFilterToolsService.filterByElements(
          data,
          filter.geographySelection,
          FilterRefEnum.BUYER_COUNTRY_CODE
        )
      : data;

    if (filter.includeOrganizations) {
      data = filter.organizationSelection?.length
        ? this.dataGridFilterToolsService.attachRows(
            this.dataSheetStorageService.gridData,
            data,
            filter.organizationSelection,
            FilterRefEnum.TARGET_ID
          )
        : data;
    }

    return data;
  }

  private _processDealInfoFilter(data: GridData[], filter: DealInfoFilterType) {
    data =
      filter.enterpriseValue?.min !== null ||
      filter.enterpriseValue?.max !== null
        ? this.dataGridFilterToolsService.filterByFinancialValueSet(
            data,
            {
              min: filter.enterpriseValue?.min,
              max: filter.enterpriseValue?.max
            },
            FilterRefEnum.EV
          )
        : data;

    // data =
    //   filter.dealSize?.min !== null || filter.dealSize?.max !== null
    //     ? this.dataGridFilterToolsService.filterByFinancialValueSet(
    //         data,
    //         {
    //           min: filter.dealSize?.min,
    //           max: filter.dealSize?.max
    //         },
    //         FilterRefEnum.DEAL_SIZE
    //       )
    //     : data;

    return data;
  }

  private _getFilterCount() {
    return 0;
  }
}
