import { Injectable } from '@angular/core';
import { FilterRefEnum } from '../../data/enum/grid/filter-ref.enum';
import { DataSheetStore } from '../store/data-sheet.store';
import { GridMutatorStore } from './grid-mutator.store';
import { Mutation } from '../../data/type/mutator/data-mutator.type';
import { GridData } from '../../data/type/organization/grid-data.type';

@Injectable({
  providedIn: 'root'
})
export class GridMutatorService {
  constructor(
    public readonly gridMutatorStorage: GridMutatorStore,
    private readonly dataSheetStorageService: DataSheetStore
  ) {
    this.dataSheetStorageService.gridDisplayData$.subscribe(val => {
      if (val.length > 0) {
        const mutation = this.gridMutatorStorage.gridMutation;

        console.debug('MUTATING: gridMutation$', val.length, mutation);

        this._applyGridDataMutator(mutation, val);
      }
    });

    this.gridMutatorStorage.gridMutation$.subscribe(val => {
      const data = this.dataSheetStorageService.gridDisplayData;

      if (data.length > 0) {
        console.debug('MUTATING: gridDisplayData$', data.length, val);

        this._applyGridDataMutator(val, data);
      }
    });
  }

  private _applyGridDataMutator(dataMutator: Mutation, data: GridData[]) {
    const skuRef = this.dataSheetStorageService.fieldMap.get(FilterRefEnum.SKU);
    const statusRef = this.dataSheetStorageService.fieldMap.get(
      FilterRefEnum.STATUS
    );

    if (statusRef && skuRef) {
      const statusOff = new Set<string>(dataMutator?.statusOff);

      data.forEach(it => (it[statusRef] = true));
      data = data.filter(it => statusOff.has(<string>it[skuRef]));
      data.forEach(it => (it[statusRef] = false));
    }
  }
}
