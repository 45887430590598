import { Injectable } from '@angular/core';
import { GridData } from '../data/type/organization/grid-data.type';
import { FilterRefEnum } from '../data/enum/grid/filter-ref.enum';
import { DataSheetStore } from './store/data-sheet.store';
import { UIOptionType } from '../data/type/filter/UIOption.type';
import { FinancialValueRange } from '../data/type/filter/grid-filter.type';

@Injectable({
  providedIn: 'root'
})
export class DataGridFilterToolsService {
  constructor(private dataSheetStorageService: DataSheetStore) {}

  /**
   * Filters a dataset by a list of tags.
   *
   * @param data
   * @param items
   * @param filterRef
   * @param characterSeparator
   */
  filterByCharacterSeparatedInnerField(
    data: GridData[],
    items: string[],
    filterRef: FilterRefEnum,
    characterSeparator: string = ';'
  ): GridData[] {
    const fldRef = this.fetchFieldRef(filterRef);

    return data.filter(it => {
      const itemTags = <string[]>it[fldRef]
        ?.toString()
        .split(characterSeparator)
        .map(t => t.trim());

      return items.filter(e => itemTags?.includes(e)).length > 0;
    });
  }

  /**
   * Filters dataset by tags included in a selected list of items of the same kind.
   *
   * @param data general data set to filter
   * @param items items to filter by
   * @param referenceItems items reference list
   * @param filterRef reference field to search for
   * @param characterSeparator tag character separator
   */
  filterGlobalElementsBySelectedElementTags(
    data: GridData[],
    items: string[],
    referenceItems: UIOptionType[],
    filterRef: FilterRefEnum,
    characterSeparator: string = ';'
  ) {
    const fldRef = this.fetchFieldRef(filterRef);

    const itemTags = [
      ...new Set<string>(
        referenceItems
          .filter(it => items.includes(it.ref))
          .map(it => (it.meta ? it.meta.map(t => t.trim()) : []))
          .flat()
      )
    ];

    return data.filter(it => {
      const tags = <string[]>it[fldRef]
        ?.toString()
        .split(characterSeparator)
        .map(t => t.trim());

      return tags.filter(tag => itemTags.includes(tag.trim())).length > 0;
    });
  }

  filterByElements(
    data: GridData[],
    items: string[],
    filterRef: FilterRefEnum
  ) {
    const fldRef = this.fetchFieldRef(filterRef);

    return data.filter(it =>
      it[fldRef] ? items?.includes(it[fldRef]!.toString()) : false
    );
  }

  filterByKeywords(
    data: GridData[],
    keywords: string[],
    fieldRefs: FilterRefEnum[]
  ) {
    const fldRefs = fieldRefs.map(it => this.fetchFieldRef(it));

    return data.filter(it => {
      const regex = new RegExp(keywords.join('|'), 'i');

      return fldRefs.some(field => regex.test(it[field] as string));
    });
  }

  filterByFinancialValueSet(
    data: GridData[],
    financialValue: FinancialValueRange,
    filterRef: FilterRefEnum
  ) {
    const fldRef = this.fetchFieldRef(filterRef);

    return data
      .filter(it =>
        financialValue.min ? <number>it[fldRef] >= financialValue.min : true
      )
      .filter(it =>
        financialValue.max ? <number>it[fldRef] <= financialValue.max : true
      );
  }

  /**
   * Finds a field reference by name.
   *
   * @param filterRef field reference name
   */
  fetchFieldRef(filterRef: FilterRefEnum) {
    return <string>this.dataSheetStorageService.fieldMap.get(filterRef);
  }

  attachRows(
    dataSet: GridData[],
    data: GridData[],
    items: string[],
    fieldRef: FilterRefEnum
  ) {
    const _data = this.filterByElements([...dataSet], items, fieldRef);

    return [...data, ..._data];
  }
}
