<div *ngIf="this.totalPages > 1" class="pager">
  <div class="nav-group">
    <a (click)="setPage(1)" *ngIf="this.currentPage > 1">
      <i class="fa-solid fa-angles-left"></i>
    </a>
    <a (click)="setPage(this.currentPage - 1)" *ngIf="this.currentPage > 1">
      <i class="fa-solid fa-angle-left"></i>
    </a>
  </div>

  <div class="page-number">
    <a
      (click)="setPage(page)"
      *ngFor="let page of pageRange"
      [ngClass]="page === this.currentPage ? 'active' : ''"
    >
      {{ page }}
    </a>
  </div>

  <div class="nav-group">
    <a
      (click)="setPage(this.currentPage + 1)"
      *ngIf="this.currentPage < this.totalPages"
    >
      <i class="fa-solid fa-angle-right"></i>
    </a>
    <a
      (click)="setPage(this.totalPages)"
      *ngIf="this.currentPage < this.totalPages"
    >
      <i class="fa-solid fa-angles-right"></i>
    </a>
  </div>
</div>
