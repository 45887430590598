import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _encryptionKey = 'v9y$B&E)H@McQfTj';

  public save(key: string, value: string): void {
    localStorage.setItem(key, this.encrypt(value));
  }

  public get(key: string): string {
    return this.decrypt(localStorage.getItem(key) || '');
  }

  public delete(key: string): void {
    localStorage.removeItem(key);
  }

  public encrypt(txt: string): string {
    if (this._encryptionKey != null) {
      return CryptoJS.AES.encrypt(txt, this._encryptionKey).toString();
    }

    throw Error('No encryption key found');
  }

  public decrypt(txt: string): string {
    if (this._encryptionKey != null) {
      return CryptoJS.AES.decrypt(txt, this._encryptionKey).toString(
        CryptoJS.enc.Utf8
      );
    }

    throw Error('No encryption key found');
  }
}
