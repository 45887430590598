<h2>Financials</h2>
<form [formGroup]="filterForm">
  <div class="flex flex-col mt-3 mb-6">
    <h3>Enterprise Value ($M)</h3>
    <div class="flex">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            (keyup)="addItem()"
            id="evMin"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="evMin"
            placeholder=" "
            type="text"
          />
          <label
            for="evMin"
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >min</label
          >
        </div>
      </div>
      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            id="evMax"
            (keyup)="addItem()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="evMax"
            placeholder=" "
            type="text"
          />
          <label
            for="evMax"
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >max</label
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-3 mb-6">
    <div class="flex flex-row">
      <h3 class="text-left w-full text-gray-600 mb-2">Revenue ($M)</h3>
      <div>
        <mul-select
          [options]="revenueMetricOptions"
          formGroupName="revenueMetric"
        >
        </mul-select>
      </div>
    </div>

    <div class="flex flex-row">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            (keyup)="addItem()"
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            formControlName="revenueMin"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >min</label
          >
        </div>
      </div>
      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            class="block peer w-full bg-gray-100 border-0 border-gray-300 text-gray-900 text-xs font-light rounded focus:ring-0 focus: outline-none focus:ring-gray-300 p-2"
            (keyup)="addItem()"
            formControlName="revenueMax"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >max</label
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-3 mb-6">
    <div class="flex flex-row">
      <h3 class="text-left w-full text-gray-600 mb-2">% revenue growth</h3>
      <div>
        <mul-select
          [options]="revenueGrowthMetricOptions"
          formGroupName="revenueGrowthMetric"
        >
        </mul-select>
      </div>
    </div>

    <div class="flex flex-row">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            (keyup)="addItem()"
            formControlName="revenueGrowthMin"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >min</label
          >
        </div>
      </div>

      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            (keyup)="addItem()"
            formControlName="revenueGrowthMax"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >max</label
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-3 mb-6">
    <div class="flex flex-row">
      <h3 class="text-left w-full text-gray-600 mb-2">EBITDA ($M)</h3>
      <div>
        <mul-select
          [options]="ebitdaMetricOptions"
          formGroupName="ebitdaMetric"
        >
        </mul-select>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex flex-col">
        <div class="relative float-label">
          <input
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            (keyup)="addItem()"
            formControlName="ebitdaMin"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >min</label
          >
        </div>
      </div>
      <div class="flex flex-col ml-2">
        <div class="relative float-label">
          <input
            class="peer focus:ring-0 focus:outline-none focus:ring-gray-300"
            (keyup)="addItem()"
            formControlName="ebitdaMax"
            placeholder=" "
            type="text"
          />
          <label
            class="duration-300 transform -translate-y-4 scale-85 origin-[0] peer-focus:px-2 peer-focus:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-85 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >max</label
          >
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-start switch">
    <label class="inline-flex items-center mt-2 cursor-pointer">
      <input
        type="checkbox"
        value="true"
        class="peer sr-only"
        (change)="addItem()"
      />
      <div
        class="
        relative
        w-9 h-5
        bg-primary-100
        dark:bg-inverse-100
        rounded-full
        peer
        peer-checked:bg-green-400
        dark:peer-checked:bg-green-600
        peer-focus:outline-none
        peer-checked:after:translate-x-full
        rtl:peer-checked:after:-translate-x-full
        peer-checked:after:border-white
        after:content-['']
        after:absolute
        after:top-[2px]
        after:start-[2px]
        after:bg-white
        after:border-gray-300
        after:border
        after:rounded-full
        after:h-4
        after:w-4
        after:transition-all
        "
      ></div>
      <span
        class="ms-3 text-xs font-medium text-primary-600 dark:text-inverse-600"
        >Must be EBITDA profitable</span
      >
    </label>
  </div>
</form>
