import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UiObserverStore {
  private _uiFocusObserver: WritableSignal<string | null> = signal(null);

  set uiFocusObserver(value: string | null) {
    this._uiFocusObserver.set(value);
  }

  get uiFocusObserver() {
    return this._uiFocusObserver();
  }
}
