<section class="text-xs">
  <div
    *ngIf="authorizationService.isEmbeddedAuth()"
    class="bg-logo_black dark:bg-logo_white bg-no-repeat w-[130px] h-[30px]"
  ></div>
  <div class="filter-nav">
    <div class="views">
      <div class="flex ">
        <button
          class="relative {{ activeFilterSection === 0 ? 'active' : '' }}"
          (click)="setActiveFilterSection(0)"
        >
          Target
        </button>
        <button
          class="relative {{ activeFilterSection === 1 ? 'active' : '' }}"
          (click)="setActiveFilterSection(1)"
        >
          Buyer
        </button>
        <button
          class="relative {{ activeFilterSection === 2 ? 'active' : '' }}"
          (click)="setActiveFilterSection(2)"
        >
          Deal Info
        </button>
      </div>
    </div>
    <mul-filter-clear></mul-filter-clear>
  </div>
  <div class="p-5 relative">
    <div *ngIf="activeFilterSection === 0">
      <mul-target-filter
        [targetOrganizationDataSet]="targetOrganizationsDataSet"
        [verticalDataSet]="verticalDataSet"
        [geographyDataSet]="geographyDataSet"
        [filterSelection]="targetFilterSelection"
        (dataMutationEventEmitter)="setTargetFilter($event)"
      ></mul-target-filter>
    </div>
    <div *ngIf="activeFilterSection === 1">
      <mul-buyer-filter
        [buyerOrganizationsDataSet]="buyerOrganizationsDataSet"
        [verticalDataSet]="verticalDataSet"
        [geographyDataSet]="geographyDataSet"
        [filterSelection]="buyerFilterSelection"
        (dataMutationEventEmitter)="setBuyerFilter($event)"
      ></mul-buyer-filter>
    </div>
    <div *ngIf="activeFilterSection === 2">
      <mul-deal-info-filter
        [filterSelection]="dealInfoFilterSelection"
        (dataMutationEventEmitter)="setDealInfoFilter($event)"
      ></mul-deal-info-filter>
    </div>
  </div>
  <mul-footer
    class="flex mt-3"
    *ngIf="authorizationService.isEmbeddedAuth()"
  ></mul-footer>
</section>
