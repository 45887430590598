import { Injectable, isDevMode } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiService } from '../../common/service/api.service';
import { StorageService } from '../../common/service/storage.service';
import { AuthKeysEnum } from '../data/enum/auth-keys.enum';
import { StoredUser } from '../data/type/stored-user.type';
import { AuthUser } from '../data/entity/auth-user.entity';
import { AuthResponse } from '../data/type/auth.response.type';
import { User } from '../../common/data/entity/user.entity';
import { ApiRoutes } from '../../common/data/enum/routes.enum';
import { AuthUserStore } from '../../data-sheet/service/store/auth-user.store';
import { SubscriptionService } from '../../subscription/services/subscription.service';
import { environment } from '../../../environments/environment';
import { Socket } from 'ngx-socket-io';
import { SessionResponseType } from '../data/type/session.response.type';
import { SessionEventEnum } from '../data/enum/session-event.enum';
import { SubscriptionEventEnum } from '../../subscription/data/enum/subscription-event.enum';
import { SubscriptionResponseType } from '../../subscription/data/type/subscription.response.type';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  logOutTimer?: ReturnType<typeof setTimeout>;

  constructor(
    private api: ApiService,
    private storageService: StorageService,
    private authUserStore: AuthUserStore,
    private subscriptionService: SubscriptionService,
    private socket: Socket
  ) {}

  login(username: string, password: string): Observable<AuthResponse> {
    return this.api
      .httpPost$<AuthResponse>(ApiRoutes.AUTHORIZATION_LOGIN, {
        username,
        password
      })
      .pipe(
        tap(res => {
          this._setAuthSocket();
          this._setSubscriptionSocket();

          this._handleAuthenticationFlow(res);
        })
      );
  }

  embeddedDomainLogin(
    host: string,
    parentHost: string
  ): Observable<AuthResponse> {
    return this.api
      .httpPost$<AuthResponse>(ApiRoutes.AUTHORIZATION_LOGIN_DOMAIN, {
        host,
        parentHost
      })
      .pipe(
        tap(res => {
          if (res.accountId) this._handleAuthenticationFlow(res);
        })
      );
  }

  subscriptionLogIn(sessionId: string) {
    return this.api
      .httpPost$<AuthResponse>(ApiRoutes.AUTHORIZATION_LOGIN_SUBSCRIPTION, {
        sessionId
      })
      .pipe(
        tap(res => {
          if (res.accountId) {
            this._setAuthSocket();
            this._setSubscriptionSocket();

            this._handleAuthenticationFlow(res);
          }
        })
      );
  }

  refreshLogIn() {
    return this.api
      .httpPost$<AuthResponse>(ApiRoutes.AUTHORIZATION_REFRESH_LOGIN)
      .pipe(
        tap(res => {
          if (res.accountId) this._handleAuthenticationFlow(res);
        })
      );
  }

  recover(email: string) {
    return this.api.httpPost$<AuthResponse>(
      ApiRoutes.AUTHORIZATION_PASSWORD_REQUEST,
      {
        email
      }
    );
  }

  restore(password: string, token: string) {
    return this.api.httpPut$<AuthResponse>(
      ApiRoutes.AUTHORIZATION_PASSWORD_RESET,
      {
        token,
        password
      }
    );
  }

  activate(token: string) {
    return this.api.httpPut$<AuthResponse>(
      ApiRoutes.AUTHORIZATION_ACCOUNT_ACTIVATION,
      {
        token
      }
    );
  }

  isDomainLogin(): boolean {
    const host = window.location.origin;
    const parentHost = document.referrer.slice(0, -1);

    return <boolean>(parentHost && host !== parentHost && !isDevMode());
  }

  autoLogin(): void {
    const userDataJson = this.storageService.get(AuthKeysEnum.USER_DATA_KEY);
    const userData: StoredUser =
      userDataJson != '' ? JSON.parse(userDataJson) : null;
    if (!userData) {
      return;
    }

    const loadedUserAuthUser = new AuthUser(
      userData.accountId,
      userData.firstName,
      userData.lastName,
      userData.email,
      userData.username,
      userData._expires,
      userData._accessToken,
      userData.roles,
      userData.authorities,
      userData.authMode,
      userData._accessDate,
      userData.options,
      userData.embeddedOptions,
      userData.subscription
    );

    if (loadedUserAuthUser.accessToken) {
      this.authUserStore.user = loadedUserAuthUser;
      this.subscriptionService.handleSubscriptionChange(
        loadedUserAuthUser?.subscription
      );

      const expires =
        new Date().getTime() - userData._accessDate + userData._expires;

      this.autoLogOut(expires);
    }
  }

  autoLogOut(expires: number): void {
    this.logOutTimer = setTimeout(() => {
      this.logOut();
    }, expires);
  }

  logOut(): void {
    this.api.httpPost$(ApiRoutes.AUTHORIZATION_LOGOUT).subscribe({
      next: () => {
        this.authUserStore.user = null;
        this.subscriptionService.handleSubscriptionChange(null);

        this.storageService.delete(AuthKeysEnum.USER_DATA_KEY);

        if (this.logOutTimer) {
          clearTimeout(this.logOutTimer);
        }

        window.location.href = environment.site_url;
      }
    });
  }

  userData(): User {
    const userStoredData = this.storageService.get(AuthKeysEnum.USER_DATA_KEY);

    if (userStoredData) {
      const authUser = <AuthUser>(
        JSON.parse(this.storageService.get(AuthKeysEnum.USER_DATA_KEY))
      );

      return User.fromAuthUser(authUser);
    }

    return new User();
  }

  private _handleAuthenticationFlow(res: AuthResponse): void {
    const user: AuthUser = new AuthUser(
      res.accountId,
      res.firstName,
      res.lastName,
      res.email,
      res.username,
      res.expires,
      res.accessToken,
      res.roles,
      res.authorities,
      res.authMode,
      undefined,
      res.options,
      res.embeddedOptions,
      res.subscription
    );

    this.storageService.save(AuthKeysEnum.USER_DATA_KEY, JSON.stringify(user));
    this.authUserStore.user = user;
    this.subscriptionService.handleSubscriptionChange(user.subscription);

    this.autoLogOut(res.expires);
  }

  private _setAuthSocket() {
    this.socket.fromEvent(SessionEventEnum.SIGN_IN_EVENT).subscribe({
      next: res => {
        if (
          this.authUserStore.user?.accountId ===
          (<SessionResponseType>res).accountId
        ) {
          this.logOut();
        }
      }
    });
  }

  private _setSubscriptionSocket() {
    this.socket
      .fromEvent(SubscriptionEventEnum.SUBSCRIPTION_CHANGE_EVENT)
      .subscribe({
        next: res => {
          if (
            this.authUserStore.user?.accountId ===
            (<SubscriptionResponseType>res).accountId
          ) {
            this.refreshLogIn().subscribe();
          }
        }
      });
  }
}
