<div class="nav-container relative z-0" (mouseleave)="closeUserMenu()">
  <div
    class="flex {{
      authorizationService.isUserPro()
        ? 'justify-around'
        : 'justify-start ml-[32px]'
    }} items-center mt-4 w-full"
  >
    <div class="logo"></div>
    <span *ngIf="authorizationService.isUserPro()" class="promo">PRO</span>
  </div>

  <nav>
    <div
      class="item {{
        navigationState.dataType === item.dataType ? 'active' : ''
      }}"
      *ngFor="let item of nav"
    >
      <i class="fa-solid {{ item.logo }}"></i>
      <span>
        <a (click)="setNavItem(item)">{{ item.label }}</a>
      </span>
    </div>

    <div
      class="hr flex h-[0.03rem] mb-4 border-0 rounded bg-primary-200 dark:bg-inverse-200"
    ></div>

    <div class="item">
      <i class="fa-solid fa-circle-check"></i>
      <span>
        <a href="{{ env.site_url }}/data" target="_blank">Our Data</a>
      </span>
    </div>

    <div class="item">
      <i class="fa-solid fa-paper-plane"></i>
      <span>
        <a href="mailto:hi@multiples.finance?subject=Customer Service Request"
          >Help & Feedback</a
        >
      </span>
    </div>
  </nav>

  <div
    class="tools bg-inherit flex items-center w-full mt-auto p-4 border-t-[0.03rem] border-t-primary-200 dark:border-t-inverse-200 text-xs"
  >
    <i
      (click)="toggleUserMenu()"
      class="fa-solid fa-circle-chevron-up duration-150 transform-gpu {{
        isUserMenuCollapsed ? 'rotate-0' : 'rotate-180'
      }} text-lg text-primary-500 dark:text-inverse-500 cursor-pointer"
    >
    </i>
    <div class="flex flex-col mx-3 flex-1">
      <span class="font-bold text-primary-500 dark:text-inverse-500"
        >{{ authService.userData().firstName }}
        {{ authService.userData().lastName }}</span
      >
      <span class="text-xxs text-primary-400 dark:text-inverse-400">{{
        authService.userData().email
      }}</span>
    </div>
    <div class="flex justify-center align-middle">
      <mul-dark-mode-switch></mul-dark-mode-switch>
    </div>
  </div>
  <div
    (mouseleave)="closeUserMenu()"
    class="w-[250px] user-options transition-all delay-0 duration-100 {{
      isUserMenuCollapsed ? 'h-[65px] opacity-0' : 'h-[150px] opacity-100'
    }}"
  >
    <div class="flex items-center">
      <i class="fa-solid fa-right-from-bracket"></i>
      <span (click)="logout()">Log out</span>
    </div>
    <div
      class="flex items-center"
      *ngIf="authorizationService.isUserCustomer()"
    >
      <i class="fa-solid fa-file-invoice-dollar"></i>
      <span (click)="goToCustomerPortal()">Manage account</span>
    </div>
    <div
      class="flex items-center"
      *ngIf="
        authorizationService.isRoleAllowed([
          RoleEnum.ROLE_ADMIN,
          RoleEnum.ROLE_SUPER_ADMIN,
          RoleEnum.ROLE_CONTENT_MANAGER
        ])
      "
    >
      <i class="fa-solid fa-gear"></i>
      <a href="{{ env.bo_url }}" target="_blank">Back office</a>
    </div>
  </div>
</div>
