import { SortingColumnDirection } from '../../enum/grid/sorting-column-direction.enum';
import { FilterRefEnum } from '../../enum/grid/filter-ref.enum';

export type Sort = {
  column: string | null;
  direction: number;
};

export class SortFactory {
  static defaultSortingDirection: SortingColumnDirection =
    SortingColumnDirection.DESC;

  static defaultSortingColumn: FilterRefEnum = FilterRefEnum.EV;

  static generate() {
    return <Sort>{
      column: null,
      direction: this.defaultSortingDirection
    };
  }
}
