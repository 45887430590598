import { RouterModule, Routes } from '@angular/router';
import { DataSheetComponent } from './data-sheet.component';
import { NgModule } from '@angular/core';
import { dataSheetResolverService } from './service/resolvers/data-sheet-resolver.service';
import { geographyFilterResolver } from './service/resolvers/geography-filter-resolver.service';
import { gridViewResolverService } from './service/resolvers/grid-view-resolver.service';
import { verticalFilterResolver } from './service/resolvers/vertical-filter-resolver.service';
import { storedGridFilterInterceptor } from './service/interceptors/stored-grid-filter-interceptor.service';
import { sharedViewGuard } from '../common/service/guards/shared-view-guard.service';
import { authGuardService } from '../common/service/guards/auth-guard.service';
import { navigationResolverService } from '../navigation/service/resolver/navigation-resolver.service';

const dataSheetRoutes: Routes = [
  {
    path: '',
    component: DataSheetComponent,
    resolve: [
      navigationResolverService,
      gridViewResolverService,
      dataSheetResolverService,
      geographyFilterResolver,
      verticalFilterResolver
    ],
    canActivate: [authGuardService]
  },
  {
    path: 'shared/:storedGridFilterId',
    component: DataSheetComponent,
    resolve: [
      navigationResolverService,
      gridViewResolverService,
      storedGridFilterInterceptor,
      geographyFilterResolver,
      verticalFilterResolver
    ],
    canActivate: [sharedViewGuard]
  },
  {
    path: 'public/:storedGridFilterId',
    component: DataSheetComponent,
    resolve: [
      navigationResolverService,
      gridViewResolverService,
      storedGridFilterInterceptor,
      geographyFilterResolver,
      verticalFilterResolver
    ],
    canActivate: [sharedViewGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(dataSheetRoutes)],
  exports: [RouterModule]
})
export class DataSheetRoutingModule {}
