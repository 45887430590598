export type DealInfoFilterType = {
  date: {
    min: number | null;
    max: number | null;
  };
  enterpriseValue: {
    min: number | null;
    max: number | null;
  };
  dealSize: {
    min: number | null;
    max: number | null;
  };
  evRevenue: {
    min: number | null;
    max: number | null;
    excludeNonReportedItems: boolean;
  };
  evEbitda: {
    min: number | null;
    max: number | null;
    excludeNonReportedItems: boolean;
  };
  strategicMA: boolean;
  isPrimaryBuyOut: boolean;
  isPortfolioAddOn: boolean;
};

export class DealInfoFilterFactory {
  static generate() {
    return <DealInfoFilterType>{
      date: {
        min: null,
        max: null
      },
      enterpriseValue: {
        min: null,
        max: null
      },
      dealSize: {
        min: null,
        max: null
      },
      evRevenue: {
        min: null,
        max: null,
        excludeNonReportedItems: false
      },
      evEbitda: {
        min: null,
        max: null,
        excludeNonReportedItems: false
      },
      strategicMA: false,
      isPrimaryBuyOut: false,
      isPortfolioAddOn: false
    };
  }
}
