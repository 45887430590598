<div class="header-main">
  <span
    [ngStyle]="{ 'width.px': header.header.width }"
    class="{{ generateClassNameFromLabel(header.header.label) }}"
  >
    <div class="header-text">
      <strong *ngFor="let label of header.header.label.split('|')">{{
        label
      }}</strong>
    </div>
    <button
      (click)="sortColumn(header.fields)"
      *ngIf="!header.subheaders || header.subheaders.length === 0"
      class="sort"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16px"
        viewBox="0 -960 960 960"
        width="16px"
        fill="currentColor"
      >
        <path
          d="M324-432v-294L219-621l-51-51 192-192 192 192-51 51-105-105v294h-72ZM600-96 408-288l51-51 105 105v-294h72v294l105-105 51 51L600-96Z"
        />
      </svg>
    </button>
  </span>
</div>

<div *ngIf="header.subheaders" class="header-sub">
  <span
    *ngFor="let subheader of header.subheaders"
    [ngStyle]="{ 'width.px': subheader.width }"
  >
    <span>{{ subheader.label }}</span>
    <button
      (click)="sortColumn(subheader.fields)"
      *ngIf="header.subheaders.length > 0"
      class="sort"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16px"
        viewBox="0 -960 960 960"
        width="16px"
        fill="currentColor"
      >
        <path
          d="M324-432v-294L219-621l-51-51 192-192 192 192-51 51-105-105v294h-72ZM600-96 408-288l51-51 105 105v-294h72v294l105-105 51 51L600-96Z"
        />
      </svg>
    </button>
  </span>
</div>
