import { BehaviorSubject } from 'rxjs';
import { AuthUser } from '../../../auth/data/entity/auth-user.entity';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthUserStore {
  private _user: BehaviorSubject<AuthUser | null> = new BehaviorSubject<AuthUser | null>(
    null
  );

  readonly user$ = this._user.asObservable();

  get user(): AuthUser | null {
    return this._user?.getValue();
  }

  set user(val: AuthUser | null) {
    this._user.next(val);
  }
}
