<main class="h-screen flex flex-col items-center justify-center tracking-tight">
  <div [style]="{ width: '22rem' }" class="auth mb-5">
    <h1 class="flex flex-col items-center mb-5">
      <a href="{{ env.site_url }}"></a>
    </h1>

    <form
      (ngSubmit)="onSubmitForm()"
      [formGroup]="formGroup"
      class="flex flex-col items-center justify-center flex-1"
    >
      <div class="flex flex-col w-full mb-5">
        <label for="username">Username</label>
        <input formControlName="username" id="username" type="email" />
        <small
          *ngIf="
            formGroup.controls['username'].touched &&
            formGroup.controls['username']?.hasError('required')
          "
          id="username-required"
        >
          Please provide an email address
        </small>
        <small
          *ngIf="
            formGroup.controls['username'].touched &&
            formGroup.controls['username']?.hasError('email')
          "
          id="username-email"
        >
          Please provide a valid email address
        </small>
      </div>

      <div class="flex flex-col w-full mb-5 group">
        <label for="password">Password</label>
        <div
          class="flex flex-row align-middle password group-focus:ring-1 group-focus:outline-none group-focus:ring-primary-300 dark:group-focus:ring-inverse-300 group-focus:border-primary-300 dark:group-focus:border-inverse-300"
        >
          <input
            [type]="passwordFieldVisibility ? 'text' : 'password'"
            class="group-focus:focus-0 group-focus:outline-none"
            formControlName="password"
            id="password"
          />
          <span
            (click)="passwordFieldVisibility = !passwordFieldVisibility"
            class="fa-regular fa-eye {{
              passwordFieldVisibility ? 'fa-eye-slash' : 'fa-eye'
            }}"
          >
          </span>
        </div>
        <small
          *ngIf="
            formGroup.controls['password'].touched &&
            formGroup.controls['password']?.hasError('required')
          "
          id="password-required"
        >
          Please provide a user account password
        </small>
      </div>

      <div class="flex flex-row justify-around links">
        <a class="underline" id="gotoSignUp" routerLink="/authorization/sign-up"
          >Don't have an account?</a
        >
        <a
          class="underline"
          id="goToAccountRecovery"
          routerLink="/authorization/account-recovery"
          >Forgot your password?</a
        >
      </div>

      <button id="login-action" type="submit">
        Log In
      </button>
    </form>
  </div>
  <mul-footer></mul-footer>
</main>
