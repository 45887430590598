import { BehaviorSubject } from 'rxjs';
import {
  DealDataFilter,
  DealDataFilterFactory
} from '../../data/type/filter/deal/deal-data-filter.type';
import { TargetFilterType } from '../../data/type/filter/deal/target-filter.type';
import { BuyerFilterType } from '../../data/type/filter/deal/buyer-filter-type';
import { DealInfoFilterType } from '../../data/type/filter/deal/deal-info-filter.type';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DealDataFilterStore {
  private _gridFilter: BehaviorSubject<DealDataFilter> = new BehaviorSubject<
    DealDataFilter
  >(DealDataFilterFactory.generate());

  private _filterCount: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  readonly gridFilter$ = this._gridFilter.asObservable();

  readonly filterCount$ = this._filterCount.asObservable();

  set targetFilter(val: TargetFilterType) {
    this.gridFilter = { ...this.gridFilter, targetFilter: val };
  }

  get targetFilter(): TargetFilterType {
    return this.gridFilter.targetFilter;
  }

  set buyerFilter(val: BuyerFilterType) {
    this.gridFilter = { ...this.gridFilter, buyerFilter: val };
  }

  get buyerFilter(): BuyerFilterType {
    return this.gridFilter.buyerFilter;
  }

  set dealInfoFilter(val: DealInfoFilterType) {
    this.gridFilter = { ...this.gridFilter, dealInfoFilter: val };
  }

  get dealInfoFilter(): DealInfoFilterType {
    return this.gridFilter.dealInfoFilter;
  }

  set filterCount(val: number) {
    this._filterCount.next(val);
  }

  get filterCount() {
    return this._filterCount.getValue();
  }

  set gridFilter(val: DealDataFilter) {
    this._gridFilter.next(val);
  }

  get gridFilter(): DealDataFilter {
    return this._gridFilter.getValue();
  }
}
