<div
  id="snack"
  class="snack {{ toast.type?.toLowerCase() }} {{
    visible ? 'visible' : 'invisible'
  }}"
>
  <div class="snack-box {{ visible ? 'opacity-100' : 'opacity-0' }}">
    <div class="icon">
      <mul-icon-alert
        *ngIf="toast.type === SnackMessageStatusEnum.ALERT"
      ></mul-icon-alert>
      <mul-icon-error
        *ngIf="toast.type === SnackMessageStatusEnum.ERROR"
      ></mul-icon-error>
      <mul-icon-info
        *ngIf="toast.type === SnackMessageStatusEnum.INFO"
      ></mul-icon-info>
      <mul-icon-success
        *ngIf="toast.type === SnackMessageStatusEnum.SUCCESS"
      ></mul-icon-success>
    </div>
    <div class="message">{{ toast.message }}</div>
    <button type="button" (click)="close()" class="close">
      <span class="fa-solid fa-xmark"></span>
    </button>
  </div>
</div>
