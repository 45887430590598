import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mul-switch',
  templateUrl: './switch.component.html'
})
export class SwitchComponent {
  @Output() dataMutationEventEmitter: EventEmitter<
    boolean
  > = new EventEmitter();

  @Input() label: string | undefined = undefined;
  @Input() value = false;

  onChangeSwitch() {
    this.value = !this.value;
    this.dataMutationEventEmitter.emit(this.value);
  }
}
