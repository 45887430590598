import { Injectable, signal, WritableSignal } from '@angular/core';
import { SnackMessageStatusEnum } from '../data/enum/snackMessageStatus.enum';
import { SnackMessageType } from '../data/type/snackMessage.type';

export const toast: WritableSignal<SnackMessageType> = signal(
  {} as SnackMessageType
);

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  defaultDurationInSeconds = 10;
  defaultStatus = SnackMessageStatusEnum.INFO;

  snack(message: SnackMessageType): void {
    toast.set({
      ...message,
      type: message.type ? message.type : this.defaultStatus,
      durationInSeconds: message.durationInSeconds
        ? message.durationInSeconds
        : this.defaultDurationInSeconds
    });
  }
}
