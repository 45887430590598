import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'mul-footer',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  currentDate: Date = new Date();
  version: string;
  app: string;

  constructor() {
    this.version = environment.version;
    this.app = environment.app;
  }

  protected readonly environment = environment;
}
