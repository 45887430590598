<form [formGroup]="filterForm">
  <div class="filter">
    <div class="keyword flex flex-col items-center justify-center">
      <h2>Buyer Keywords<i class="fa-solid fa-circle-info ml-1"></i></h2>
      <form>
        <textarea
          (keyup)="onFormKeywordsItemChange()"
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          formControlName="keywords"
          id="keywords"
          placeholder="Type in keywords only, e.g.&#10;&#10;defense technology, aerospace, military tech, govtech, data protection tools"
          rows="4"
        ></textarea>
      </form>
    </div>
  </div>
  <div class="filter">
    <mul-multi-select
      (dataMutationEventEmitter)="setOrganizations($event)"
      [dataSet]="buyerOrganizationsDataSet"
      [selection]="buyerOrganizationSelection"
      componentId="target_name"
      label="Buyer Names"
      placeholder="Start typing a name"
      tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod."
    ></mul-multi-select>
    <mul-switch
      (dataMutationEventEmitter)="setIncludeOrganizations($event)"
      [value]="filterSelection.includeOrganizations || false"
      label="Include in results"
    ></mul-switch>
    <mul-switch
      (dataMutationEventEmitter)="setConditionalIncludeOrganizations($event)"
      [value]="filterSelection.conditionalIncludeOrganizations || true"
      label="Include ONLY if all other criteria are meet"
    ></mul-switch>
  </div>
  <mul-multi-select
    (dataMutationEventEmitter)="setGeography($event)"
    [dataSet]="geographyDataSet"
    [selection]="geographySelection"
    class="filter"
    componentId="target_geography"
    label="Buyer Geography"
    placeholder="E.g. Europe, Brazil or India"
    tooltip="Lorem ipsum dolor sit amet, consectetur."
  ></mul-multi-select>
  <div class="filter">
    <div class="flex flex-col justify-center">
      <h2>Buyer Type</h2>
      <div class="mb-2">
        <mul-switch
          label="Strategy acquirer"
          (dataMutationEventEmitter)="onStrategyAcquirerChange($event)"
        ></mul-switch>
        <div class="switch-block flex flex-col ml-5">
          <mul-switch
            (dataMutationEventEmitter)="setIsPublic($event)"
            [value]="filterSelection.isPublic || false"
            label="Public Company"
          ></mul-switch>
          <mul-switch
            (dataMutationEventEmitter)="setIsPrivate($event)"
            [value]="filterSelection.isPrivate || false"
            label="Private Company"
          ></mul-switch>
        </div>
      </div>
      <div class="mb-2">
        <mul-switch
          label="PE investor"
          (dataMutationEventEmitter)="onPEInvestorChange($event)"
        ></mul-switch>
        <div class="switch-block flex flex-col ml-5">
          <mul-switch
            (dataMutationEventEmitter)="setIsPrimaryBuyout($event)"
            [value]="filterSelection.isPrimaryBuyOut || false"
            label="Primary buyout"
          ></mul-switch>
          <mul-switch
            (dataMutationEventEmitter)="setIsPortfolioAddOn($event)"
            [value]="filterSelection.isPortfolioAddOn || false"
            label="Portfolio add-on"
          ></mul-switch>
        </div>
      </div>
    </div>
  </div>
</form>
