import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterRefEnum } from '../../../data/enum/grid/filter-ref.enum';
import {
  ColumnSubheader,
  GridHeader
} from '../../../data/type/grid/grid-header.type';
import { DataSheetStore } from '../../../service/store/data-sheet.store';
import { AuthorizationService } from '../../../../common/service/authorization.service';
import { FinancialFilterData } from '../../../data/type/filter/financial-filter.type';
import { PublicDataGridFilterService } from '../../../service/public-data-grid-filter.service';
import { AccessPromoService } from '../../../../auth/services/access-promo.service';
import { UiObserverStore } from '../../../../uicommon/service/ui-observer.store';
import { UIOptionType } from '../../../data/type/filter/UIOption.type';
import { PromoEnum } from '../../../../auth/data/enum/promo.enum';

@Component({
  selector: 'mul-financial-filter',
  templateUrl: './financial-filter.component.html'
})
export class FinancialFilterComponent {
  filterForm: FormGroup = this.formBuilder.group({
    evMin: '',
    evMax: '',
    revenueMin: '',
    revenueMax: '',
    revenueMetric: '',
    revenueGrowthMin: '',
    revenueGrowthMax: '',
    revenueGrowthMetric: '',
    ebitdaMin: '',
    ebitdaMax: '',
    ebitdaMetric: '',
    ebitdaProfitable: false
  });

  revenueMetricOptions: UIOptionType[] = [];

  revenueGrowthMetricOptions: UIOptionType[] = [];

  ebitdaMetricOptions: UIOptionType[] = [];

  constructor(
    private readonly gridFilterService: PublicDataGridFilterService,
    private readonly dataSheetStore: DataSheetStore,
    private readonly authorizationService: AuthorizationService,
    private readonly accessPromoService: AccessPromoService,
    private readonly formBuilder: FormBuilder,
    private readonly uiObserverService: UiObserverStore
  ) {
    this.dataSheetStore.gridHeader$.subscribe(val => {
      this.revenueMetricOptions = this._buildRevenueOptions(val);
      this.ebitdaMetricOptions = this._buildEbitdaOptions(val);
      this.revenueGrowthMetricOptions = this._buildGrowthMetricOptions(val);

      if (this.revenueMetricOptions != undefined) {
        this.filterForm
          .get('revenueMetric')
          ?.patchValue(this.revenueMetricOptions[0]?.index);
      }

      if (this.ebitdaMetricOptions != undefined) {
        this.filterForm
          .get('ebitdaMetric')
          ?.patchValue(this.ebitdaMetricOptions[0]?.index);
      }

      if (this.revenueGrowthMetricOptions != undefined) {
        this.filterForm
          .get('revenueGrowthMetric')
          ?.patchValue(this.revenueGrowthMetricOptions[0]?.index);
      }
    });

    this.gridFilterService.gridFilterStore.filterCount$.subscribe(val => {
      if (val === 0) this.clearFilter();
    });

    this.gridFilterService.gridFilterStore.gridFilter$.subscribe(val => {
      this.filterForm.get('evMin')?.patchValue(val.ev?.min ? val.ev?.min : '');
      this.filterForm.get('evMax')?.patchValue(val.ev?.max ? val.ev?.max : '');

      this.filterForm
        .get('revenueMin')
        ?.patchValue(val.revenue?.min ? val.revenue?.min : '');
      this.filterForm
        .get('revenueMax')
        ?.patchValue(val.revenue?.max ? val.revenue?.max : '');
      this.filterForm
        .get('revenueMetric')
        ?.patchValue(
          val.revenue?.index
            ? val.revenue?.index
            : this.revenueMetricOptions
            ? this.revenueMetricOptions[0]?.index
            : ''
        );

      this.filterForm
        .get('revenueGrowthMin')
        ?.patchValue(val.revenueGrowth?.min ? val.revenueGrowth?.min : '');
      this.filterForm
        .get('revenueGrowthMax')
        ?.patchValue(val.revenueGrowth?.max ? val.revenueGrowth?.max : '');
      this.filterForm
        .get('revenueGrowthMetric')
        ?.patchValue(
          val.revenueGrowth?.index
            ? val.revenueGrowth?.index
            : this.revenueGrowthMetricOptions
            ? this.revenueGrowthMetricOptions[0]?.index
            : ''
        );

      this.filterForm
        .get('ebitdaMin')
        ?.patchValue(val.ebitda?.min ? val.ebitda?.min : '');
      this.filterForm
        .get('ebitdaMax')
        ?.patchValue(val.ebitda?.max ? val.ebitda?.max : '');
      this.filterForm
        .get('ebitdaMetric')
        ?.patchValue(
          val.ebitda?.index
            ? val.ebitda?.index
            : this.ebitdaMetricOptions
            ? this.ebitdaMetricOptions[0]?.index
            : ''
        );

      this.filterForm
        .get('ebitdaProfitable')
        ?.patchValue(val.profitable ? val.profitable : '');
    });
  }

  addItem() {
    if (!this.authorizationService.isSubscribed()) {
      this.accessPromoService.show(PromoEnum.FILTER);
      this.clearFilter();

      return;
    }
    this.uiObserverService.uiFocusObserver = null;

    const formValues = this.buildFilter();

    this.gridFilterService.gridFilterStore.enterpriseValue = {
      min: formValues.evMin,
      max: formValues.evMax,
      index: null
    };

    this.gridFilterService.gridFilterStore.revenue = {
      min: formValues.revenueMin,
      max: formValues.revenueMax,
      index: formValues.revenueMetric
    };

    this.gridFilterService.gridFilterStore.revenueGrowth = {
      min: formValues.revenueGrowthMin,
      max: formValues.revenueGrowthMax,
      index: formValues.revenueGrowthMetric
    };

    this.gridFilterService.gridFilterStore.ebitda = {
      min: formValues.ebitdaMin,
      max: formValues.ebitdaMax,
      index: formValues.ebitdaMetric
    };

    this.gridFilterService.gridFilterStore.profitable =
      formValues.ebitdaProfitable;
  }

  private buildFilter() {
    const formValue: FinancialFilterData = {
      evMin: this.filterForm.get('evMin')?.value,
      evMax: this.filterForm.get('evMax')?.value,
      revenueMin: this.filterForm.get('revenueMin')?.value,
      revenueMax: this.filterForm.get('revenueMax')?.value,
      revenueMetric: this.filterForm.get('revenueMetric')?.value,
      revenueGrowthMin: this.filterForm.get('revenueGrowthMin')?.value,
      revenueGrowthMax: this.filterForm.get('revenueGrowthMax')?.value,
      revenueGrowthMetric: this.filterForm.get('revenueGrowthMetric')?.value,
      ebitdaMin: this.filterForm.get('ebitdaMin')?.value,
      ebitdaMax: this.filterForm.get('ebitdaMax')?.value,
      ebitdaMetric: this.filterForm.get('ebitdaMetric')?.value,
      ebitdaProfitable: this.filterForm.get('ebitdaProfitable')?.value
    };

    return formValue;
  }

  private clearFilter() {
    this.filterForm.get('evMin')?.patchValue('');
    this.filterForm.get('evMax')?.patchValue('');
    this.filterForm.get('revenueMin')?.patchValue('');
    this.filterForm.get('revenueMax')?.patchValue('');
    this.filterForm.get('revenueGrowthMin')?.patchValue('');
    this.filterForm.get('revenueGrowthMax')?.patchValue('');
    this.filterForm.get('ebitdaMin')?.patchValue('');
    this.filterForm.get('ebitdaMax')?.patchValue('');
    this.filterForm.get('ebitdaProfitable')?.patchValue(false);
  }

  private _buildRevenueOptions(val: GridHeader[] | null): UIOptionType[] {
    const revenuesList = val?.filter(header =>
      header.subheaders?.find(subheader =>
        subheader.fields?.some(it => it.field == FilterRefEnum.REVENUES)
      )
    );

    const revenues = revenuesList
      ? revenuesList[revenuesList.length - 1]
      : null;

    return revenues
      ? revenues?.subheaders.flat().map((it: ColumnSubheader) => ({
          label: it.label,
          ref: it.fields[0].ref,
          index: it.fields[0].index
        }))
      : [];
  }

  private _buildEbitdaOptions(val: GridHeader[] | null): UIOptionType[] {
    const ebitdaList = val?.filter(header =>
      header.subheaders?.find(subheader =>
        subheader.fields?.some(it => it.field == FilterRefEnum.EBITDA)
      )
    );

    const ebitda = ebitdaList ? ebitdaList[ebitdaList.length - 1] : null;

    return ebitda
      ? ebitda?.subheaders.flat().map((it: ColumnSubheader) => ({
          label: it.label,
          ref: it.fields[0].ref,
          index: it.fields[0].index
        }))
      : [];
  }

  private _buildGrowthMetricOptions(val: GridHeader[] | null): UIOptionType[] {
    const revenueGrowthList = val?.filter(header =>
      header.subheaders?.find(subheader =>
        subheader.fields?.some(
          it => it.field == FilterRefEnum.EV_REVENUE_GROWTH
        )
      )
    );

    const revenueGrowth = revenueGrowthList
      ? revenueGrowthList[revenueGrowthList.length - 1]
      : null;

    return revenueGrowth
      ? revenueGrowth?.subheaders.flat().map((it: ColumnSubheader) => ({
          label: it.label,
          ref: it.fields[0].ref,
          index: it.fields[0].index
        }))
      : [];
  }
}
