import { Component, effect } from '@angular/core';
import { DialogType } from '../../data/type/dialog.type';
import { dialog } from '../../service/dialog.service';
import { DialogStatusEnum } from '../../data/enum/dialogStatus.enum';

@Component({
  selector: 'mul-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {
  protected readonly DialogStatusEnum = DialogStatusEnum;
  visible = false;
  dialog: DialogType = {} as DialogType;

  constructor() {
    effect(() => {
      this.dialog = dialog();
      this.visible = this.dialog.status || false;
    });
  }

  cancel() {
    this.visible = false;
  }

  do() {
    this.visible = false;
    this.dialog.action();
  }
}
