import { Component } from '@angular/core';
import { PublicDataGridFilterService } from '../../../service/public-data-grid-filter.service';
import { UiObserverStore } from '../../../../uicommon/service/ui-observer.store';

@Component({
  selector: 'mul-filter-clear',
  templateUrl: './filter-clear.component.html'
})
export class FilterClearComponent {
  filterCount = 0;

  constructor(
    public readonly gridFilterService: PublicDataGridFilterService,
    private readonly uiObserverService: UiObserverStore
  ) {
    this.gridFilterService.gridFilterStore.filterCount$.subscribe({
      next: val => {
        this.filterCount = val;
      }
    });
  }

  clearFilters() {
    this.gridFilterService.clearFilters();

    this.uiObserverService.uiFocusObserver = null;
  }
}
