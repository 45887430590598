import { TargetFilterFactory, TargetFilterType } from './target-filter.type';
import { BuyerFilterFactory, BuyerFilterType } from './buyer-filter-type';
import {
  DealInfoFilterFactory,
  DealInfoFilterType
} from './deal-info-filter.type';

export type DealDataFilter = {
  targetFilter: TargetFilterType;
  buyerFilter: BuyerFilterType;
  dealInfoFilter: DealInfoFilterType;
};

export class DealDataFilterFactory {
  static generate() {
    return <DealDataFilter>{
      targetFilter: TargetFilterFactory.generate(),
      buyerFilter: BuyerFilterFactory.generate(),
      dealInfoFilter: DealInfoFilterFactory.generate()
    };
  }
}
