import { Sort, SortFactory } from '../grid/sort.type';
import { GridFilter, GridFilterFactory } from './grid-filter.type';
import { Mutation, MutationFactory } from '../mutator/data-mutator.type';

export type StoredGridFilter = {
  id?: string | null;
  saved?: boolean;
  name?: string;
  description?: string;
  view: string;
  sorting: Sort;
  page: number;
  filter: GridFilter;
  mutation: Mutation;
};

export class StoredGridFilterFactory {
  static generate() {
    return <StoredGridFilter>{
      id: null,
      view: '',
      sorting: SortFactory.generate(),
      page: 1,
      filter: GridFilterFactory.generate(),
      mutation: MutationFactory.generate()
    };
  }
}
