export enum ColumnFieldTypeEnum {
  SWITCH = 'SWITCH',
  IMAGE = 'IMAGE',
  ICON = 'ICON',
  LIST = 'LIST',
  STRING = 'STRING',
  SHORT_STRING = 'SHORT_STRING',
  NUMBER = 'NUMBER',
  YEAR = 'YEAR',

  DECIMAL = 'DECIMAL',
  PERCENTAGE = 'PERCENTAGE',
  LONG_NUMBER = 'LONG_NUMBER',
  TEXT = 'TEXT'
}
