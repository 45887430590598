import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from '../../../uicommon/service/snack-bar.service';
import { environment } from '../../../../environments/environment';
import { SnackMessageStatusEnum } from '../../../uicommon/data/enum/snackMessageStatus.enum';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent {
  public env = environment;

  formGroup: FormGroup = this._formBuilder.group({
    password: [null, [Validators.required]]
  });

  token = '';

  public passwordFieldVisibility = false;

  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private snackBarService: SnackBarService
  ) {
    this.spinner?.hide('global');
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  onSubmitForm() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.spinner?.show('global');

    this.authService
      .restore(this.formGroup.controls['password'].value, this.token)
      .subscribe({
        complete: () => {
          this.router.navigate(['/authorization/login']);
          this.snackBarService.snack({
            message: 'Your password has been successfully restored.'
          });
        },
        error: () => {
          this.spinner?.hide('global');
          this.snackBarService.snack({
            type: SnackMessageStatusEnum.ERROR,
            message: 'An error has occurred while restoring your password'
          });
        }
      });
  }
}
