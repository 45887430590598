import { Injectable, signal, WritableSignal } from '@angular/core';
import { PromoEnum } from '../data/enum/promo.enum';

@Injectable({
  providedIn: 'root'
})
export class AccessPromoService {
  dialog: WritableSignal<PromoEnum | null> = signal(null);

  show(promoType: PromoEnum) {
    this.dialog.set(promoType);
  }

  hide() {
    this.dialog.set(null);
  }
}
