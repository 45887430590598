<div *ngIf="datasource.length > 0 && authorizationService.isStandardAuth()">
  <button (click)="toggle()" [disabled]="savedSearchesActionState">
    <label>
      {{ selectedItem?.name || "Saved searches" }}
    </label>
    <i class="fa-solid fa-angle-down"></i>
  </button>
  <div
    class="relative"
    *ngIf="displaySavedSearchesPanel"
    #uiContainer
    (mouseleave)="close()"
  >
    <div class="dropdown-layer">
      <mul-stored-grid-item
        [item]="item"
        (loadStoredGridFilterEvent)="load($event)"
        *ngFor="let item of datasource"
      ></mul-stored-grid-item>
    </div>
  </div>
</div>
